import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/index';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';
import './main.css';
import { saveState,loadState } from './util.js';
import Throttle from 'lodash/throttle';
import * as serviceWorker from './serviceWorker';
import Login from './components/Login';
import Home from './components/Home';
import Agent from './components/Agent';
import Group from './components/Group';
import ShiftConfig from './components/ShiftConfig';
import BreakConfig from './components/BreakConfig';
import GeneralIssue from './components/GeneralIssue';
import ReportConfig from './components/ReportConfig';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';



const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(reduxThunk)));

store.subscribe(
    Throttle(() => {
        saveState({
          auth: store.getState().auth
        });
    }, 1000)
);

const App = () => (
  <div style={{position:'relative'}}>
    <Route path="/" exact component={Login} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/home" component={Home} />
    <Route exact path="/agent" component={Agent} />
    <Route exact path="/group" component={Group} />
    <Route exact path="/shiftConfig" component={ShiftConfig} />
    <Route exact path="/breakConfig" component={BreakConfig} />
    <Route exact path="/generalIssueConfig" component={GeneralIssue} />
    <Route exact path="/reportConfig" component={ReportConfig} />
    <Route exact path="/forgotPassword" component={ForgotPassword} />
    <Route exact path="/reset" component={ResetPassword} />
    <Route exact path="/changePassword" component={ChangePassword} />
  </div>
);

ReactDOM.render(
  <Provider store={store}>
      <HashRouter>
          <App />
      </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {
    GET_ALL_REPORT_CONFIG_SUCCESS,
    GET_ALL_REPORT_CONFIG_FAILURE,
    SAVE_REPORT_CONFIG_SUCCESS,
    SAVE_REPORT_CONFIG_FAILURE,
    EDIT_REPORT_CONFIG_SUCCESS,
    EDIT_REPORT_CONFIG_FAILURE,
    DELETE_REPORT_CONFIG_SUCCESS,
    DELETE_REPORT_CONFIG_FAILURE,
    ACTIVE_REPORT_CONFIG_SUCCESS,
    ACTIVE_REPORT_CONFIG_FAILURE,
    DEACTIVE_REPORT_CONFIG_SUCCESS,
    DEACTIVE_REPORT_CONFIG_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state  , action  ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_REPORT_CONFIG_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_REPORT_CONFIG_FAILURE:
            state = {
                isReportGetError : true,
                reportGetErrorMessage : action.payload.message
            }
            return state;
        case SAVE_REPORT_CONFIG_SUCCESS:
            state = {
                isReportSave : true
            };
            return state;

        case SAVE_REPORT_CONFIG_FAILURE:
            state = {
                isReportSave : false,
                reportSaveErrorMessage : action.payload.message
            }
            return state;
        case DELETE_REPORT_CONFIG_SUCCESS:
            state = {
                isReportDelete : true
            };
            return state;

        case DELETE_REPORT_CONFIG_FAILURE:
            state = {
                isReportDelete : false,
                reportDeleteErrorMessage : action.payload.message
            }
            return state;

        case EDIT_REPORT_CONFIG_SUCCESS:
            state = {
                isReportEdit : true
            };
            return state;

        case EDIT_REPORT_CONFIG_FAILURE:
            state = {
                isReportEdit : false,
                reportEditErrorMessage : action.payload.message
            }
            return state;
        case ACTIVE_REPORT_CONFIG_SUCCESS:
            state = {
                isReportActive : true
            };
            return state;

        case ACTIVE_REPORT_CONFIG_FAILURE:
            state = {
                isReportActive : false,
                reportActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_REPORT_CONFIG_SUCCESS:
            state = {
                isReportDeactive : true
            };
            return state;

        case DEACTIVE_REPORT_CONFIG_FAILURE:
            state = {
                isReportDeactive : false,
                reportDeactiveErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import axios from 'axios';

import {
    // SELECT_MENU_ACTION,
    LOGOUT_ACTION,
    LOGIN_SUCCESS_ACTION,
    LOGIN_FAILURE_ACTION,
    USER_PASSWORD_CHANGE_SUCCESS,
    USER_PASSWORD_CHANGE_FAILURE,
    FORCELOGOUT_AGENT_SUCCESS,
    FORCELOGOUT_AGENT_FAILURE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from './type.js';

import config from './../config.js';

export const loginAction = (username , password ) =>{
    return function (dispatch) {
        axios.post(config.api_url + '/adminUser/login', {
                email: username,
                password: password
            })
            .then(function (response) {
                dispatch({
                    type: LOGIN_SUCCESS_ACTION,
                    payload: response.data.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: LOGIN_FAILURE_ACTION,
                    payload: error
                });
        });
    };
}

export const logoutAction = () => {
    return {
        type: LOGOUT_ACTION
    }
}

// export const selectMenuAction = (selectedMenu) => {
//     return {
//         type: SELECT_MENU_ACTION,
//         payload: selectedMenu
//     }
// }

export const changePasswordAction = (data) =>{
    return function (dispatch) {
        axios.post(config.api_url+"/adminUser/changepassword" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: USER_PASSWORD_CHANGE_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: USER_PASSWORD_CHANGE_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: USER_PASSWORD_CHANGE_FAILURE,
                payload: error
            });
        });
    };
}

export const forgotPasswordAction = (data) =>{
    return function (dispatch) {
        axios.post(config.api_url+"/adminUser/forgotpassword" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: FORCELOGOUT_AGENT_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: FORCELOGOUT_AGENT_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: FORCELOGOUT_AGENT_FAILURE,
                payload: error
            });
        });
    };
}

export const resetPasswordAction = (data) =>{
    return function (dispatch) {
        axios.post(config.api_url+"/adminUser/resetpassword" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: RESET_PASSWORD_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: RESET_PASSWORD_FAILURE,
                payload: error
            });
        });
    };
}
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Icon , Dimmer , Loader,Segment ,Grid} from 'semantic-ui-react';

import { GetServices} from './../action/services';
import { isLoggedIn , setupAxios } from '../util';

class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data : {}
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetServices();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : false });
    if(nextProps.services && nextProps.services.length){
      for(let i=0; i< nextProps.services.length; i++){
        let words = nextProps.services[i].name.split(" ");
        words = words.map((word) => { 
          return word[0].toUpperCase() + word.substring(1).toLowerCase(); 
        })
        nextProps.services[i].name = words.join(" ");
      }
      this.setState({ data : nextProps.services});
    }else if(nextProps.services.isServiceGetError){
      this.setState({ data : {} });
    }
  }


  render() {
    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
            color: '#808080',           
            float: 'right',
            cursor: 'pointer',
            marginTop: 0,
            position: 'absolute',
            right: 19
        }}>
        <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div  style={{margin : 20 }}>
            <h2 style={{color:"#4495fe"}}>Live Status of Each Email Management Services</h2>

            { this.state.data.length && 
            <Segment style={{ border:'0px', boxShadow:'0 0 0 0',height : this.state.height }}>
              <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' ,marginRight : '10px'}}>
                  <Grid divided='vertically'>
                    <Grid.Row columns={4}>
                        {this.state.data.map((item, index) => {
                            return (
                                <Grid.Column key={index}>
                                {item.status === 1 &&
                                  <div 
                                      className="slider-item" 
                                      style={{ backgroundColor:"green", color:"white" ,textAlign : 'center', padding : 20, paddingTop:30}}
                                  >   
                                      {item.name}
                                      <br/><br/>
                                      <Icon name={'arrow up'} size='big'></Icon>
                                  </div>
                                }
                                {!item.status &&
                                  <div 
                                      className="slider-item" 
                                      style={{ backgroundColor:"red", color:"white" ,textAlign : 'center', padding : 20, paddingTop:30, }}
                                  >
                                      {item.name}
                                      <br/><br/>
                                      <Icon name={'arrow down'} size='big'></Icon>
                                  </div> 
                                }
                            </Grid.Column>
                            )
                        })
                      }
                    </Grid.Row>
                  </Grid>
              </div>
            </Segment>
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      services : state.services
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetServices
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Group));
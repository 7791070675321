import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Segment, Input, Icon, Button } from 'semantic-ui-react';
import { Grid, GridColumn, Message, GridRow } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { isLoggedIn } from '../util';
import { loginAction } from '../action/login';

class Login extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        }
    }


    componentWillMount() {
        if (isLoggedIn(this.props.auth)) {
            if(this.props.auth.role === 'agent'){
                this.props.history.push(`/changePassword`);
            }else{
                this.props.history.push(`/home`);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if (isLoggedIn(nextProps.auth)) {
            if(nextProps.auth.role === 'agent'){
                this.props.history.push(`/changePassword`);
            }else{
                this.props.history.push(`/home`);
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isLoggedIn(nextProps.auth)) {
            if(nextProps.auth.role === 'agent'){
                this.props.history.push(`/changePassword`);
            }else{
                this.props.history.push(`/home`);
            }
            return false;
        }
        return true;
    }

    forgotPassword = () => {
        this.props.history.push(`/forgotPassword`);
    }

    handleUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    }

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.authenticate();
        }
    }

    authenticate = () => {
        this.props.login(this.state.username, this.state.password);
    }

    render() {
        var loginBoxStyle = {
            borderRadius: 6,
            WebkitBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            MozBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            boxShadow: '10px 11px 81px 3px rgba(191,191,191,1)'
        }
        return (
            <div style={{marginTop : '20%'}}>
            <Grid columns='equal' verticalAlign='middle' style={{height: '100%'}} centered>
                <GridRow className="vcenter">
                    <Grid.Column></Grid.Column>
                    <GridColumn width={10}>
                        <Segment raised style={loginBoxStyle}>
                            <Grid columns={2}>
                                <GridRow columns='equal' divided>
                                    <GridColumn>
                                        {/* <img src='../images/textLogo.png' /> */}
                                    </GridColumn>
                                    <GridColumn verticalAlign="middle" style={{ paddingLeft: 50 }}>
                                        {this.props.auth.loginError &&
                                            <Message warning>
                                                <Message.Header>Login Failed!</Message.Header>
                                                <p>Your email or password doesn't look right.</p>
                                            </Message>
                                        }
                                        <Input iconPosition='left' placeholder='Your Email Address' onChange={this.handleUsernameChange}>
                                            <Icon name='at' />
                                            <input />
                                        </Input><br /><br />
                                        <Input iconPosition='left' type="password" placeholder='Password' onChange={this.handlePasswordChange} onKeyDown={this.handleKeyPress}>
                                            <Icon name='lock' />
                                            <input />
                                        </Input><br /><br />
                                        <div style={{display:'flex' , flexDirection:'row'}}>
                                            <Button primary onClick={this.authenticate}>Login</Button>
                                            <div style={{marginLeft : 'auto' , marginTop : 10 , cursor:'pointer' }} onClick={this.forgotPassword}>Forgot Password?</div>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </Segment>
                    </GridColumn>
                    <GridColumn></GridColumn>
                </GridRow>
            </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ login: loginAction }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

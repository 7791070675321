import {
    USER_PASSWORD_CHANGE_SUCCESS,
    USER_PASSWORD_CHANGE_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function ( state, action  ) {
    if (typeof state === 'undefined') {
        state = INIT_STATE
    }
    switch (action.type) {
        
        case USER_PASSWORD_CHANGE_SUCCESS:
            state = {
                passwordChanged : true
            } ;
            return state;
        case USER_PASSWORD_CHANGE_FAILURE:
            state = {
                changepasswordErrorMessage : action.payload.message,
                passwordChanged : false
            } ;
            return state;
        default :
            return state;
    }
}
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Segment, Input, Icon, Button,Dimmer , Loader } from 'semantic-ui-react';
import { Grid, GridColumn, Message, GridRow } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import queryString from 'querystring';
import passwordValidator from 'password-validator';

import { isLoggedIn } from '../util';
import { resetPasswordAction } from '../action/login';

class Reset extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            hiddenPassword : true,
            hiddenConfirmPassword : true,
            email: "",
            password: "",
            token:"",
            confirmPassword : "",
            showSuccessMsg : false,
            showFailureMsg : false,
            successMsg : '',
            failureMsg : '',
            isLoadingFlag : false,
            validPassword : false,
            validConfirmPassword : false
        }
    }


    componentWillMount() {
        if (isLoggedIn(this.props.auth)) {
            this.props.history.push(`/home`);
        }
        let query = queryString.parse(this.props.location.search);
        let email = query['?email'];
        this.setState({email : email})

        let token = query['token'];
        console.log( query, "query");
        console.log(token, "token")
        this.setState({token : token})
    }

    componentWillReceiveProps(nextProps){
        if (isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/home`);
        }
        this.setState({isLoadingFlag : false })
        if(nextProps.resetpassword.isResetPassword){
            this.setState({
                hiddenPassword : true,
                hiddenConfirmPassword : true,
                email : '',
                token: '',
                password : '',
                confirmPassword : '',
                showFailureMsg : false,
                showSuccessMsg : true,
                successMsg : "Your password reset successfully."
            })
        }else if(nextProps.resetpassword.resetPasswordErrorMessage && !nextProps.resetpassword.isResetPassword){
            this.setState({
                showFailureMsg : true,
                showSuccessMsg : false,
                failureMsg : nextProps.resetpassword.resetPasswordErrorMessage
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/home`);
            return false;
        }
        return true;
    }

    login = () =>{
        this.props.history.push(`/login`);
    }

    togglePasswordShow() {
        this.setState({ hiddenPassword: !this.state.hiddenPassword });
    }

    toggleConfirmPasswordShow() {
        this.setState({ hiddenConfirmPassword: !this.state.hiddenConfirmPassword });
    }

    handleUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    }

    handlePasswordChange = (e) => {
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  

        if( e.target.value.length <= 24 ){
            var checkPassword = schema.validate(e.target.value);
            if(checkPassword){
                this.setState({
                    password : e.target.value,
                    validPassword : false
                }) 
            }else{
                this.setState({
                    password : e.target.value,
                    validPassword : true
                }) 
            }
        }
    }

    handleConfirmPasswordChange = (e) => {
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  
    
        if( e.target.value.length <= 24 ){
            var checkPassword = schema.validate(e.target.value);
            if(checkPassword){
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : false,
                    passwordNotMached : false
                }) 
            }else{
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : true
                }); 
            }
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.reset();
        }
    }

    reset = () =>{
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  
        var checkPassword = schema.validate(this.state.password);
        var checkConfirmPassword = schema.validate(this.state.confirmPassword);
        if(checkPassword && checkConfirmPassword ){
            this.setState({isLoadingFlag : true })
            this.props.resetPasswordAction({email : this.state.email , token:this.state.token, password : this.state.password , confirmPassword : this.state.confirmPassword })
        }else{
            if(!checkPassword){
                this.setState({
                    validPassword : true
                }); 
            }
            if(!checkConfirmPassword){
                this.setState({
                    validConfirmPassword : true
                }); 
            }
        }

    }

    render() {
        var loginBoxStyle = {
            borderRadius: 6,
            WebkitBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            MozBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            boxShadow: '10px 11px 81px 3px rgba(191,191,191,1)'
        }
        return (
            <div style={{marginTop : '20%'}}>
            {this.state.isLoadingFlag && 
                <Dimmer active>
                    <Loader size="large">Loading</Loader>
                </Dimmer>
            }
            <Grid columns='equal' verticalAlign='middle' style={{height: '100%'}} centered>
                <GridRow className="vcenter">
                    <Grid.Column></Grid.Column>
                    <GridColumn width={10}>
                        <Segment raised style={loginBoxStyle}>
                            <Grid columns={2}>
                                <GridRow columns='equal' divided>
                                    <GridColumn>
                                        {/* <img src='../images/textLogo.png' /> */}
                                    </GridColumn>
                                    <GridColumn verticalAlign="middle" style={{ paddingLeft: 50 }}>
                                        {this.state.showFailureMsg &&
                                            <Message warning>
                                                <Message.Header>{this.state.failureMsg}</Message.Header>
                                            </Message>
                                        }
                                        {this.state.showSuccessMsg &&
                                            <Message success>
                                                <Message.Header>{this.state.successMsg}</Message.Header>
                                            </Message>
                                        }
                                        <Input iconPosition='left' value={this.state.email} readOnly={true} placeholder='Your Email Address' onChange={this.handleUsernameChange}>
                                            <Icon name='at' />
                                            <input />
                                        </Input><br /><br />
                                        <Input iconPosition='left'  type={this.state.hiddenPassword ? 'password' : 'text'} value={this.state.password} placeholder='Password' onChange={this.handlePasswordChange} onKeyDown={this.handleKeyPress}>
                                            <Icon name='lock' onClick={this.togglePasswordShow}  />
                                            <input />
                                        </Input>
                                        <label style={this.state.validPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                                        <br /><br />
                                        <Input iconPosition='left' type={this.state.hiddenConfirmPassword ? 'password' : 'text'} value={this.state.confirmPassword}   placeholder='Confirm Password' onChange={this.handleConfirmPasswordChange} onKeyDown={this.handleKeyPress}>
                                            <Icon name='lock' onClick={this.toggleConfirmPasswordShow}  />
                                            <input />
                                        </Input>
                                        <label style={this.state.validConfirmPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                                        <br /><br />
                                        <div style={{display:'flex' , flexDirection:'row'}}>
                                            <Button primary onClick={this.reset}>Submit</Button>
                                            <div style={{marginLeft : 'auto' , marginTop : 10 , cursor:'pointer' }} onClick={this.login}>Login</div>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </Segment>
                    </GridColumn>
                    <GridColumn></GridColumn>
                </GridRow>
            </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        resetpassword : state.resetpassword
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ resetPasswordAction }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reset));

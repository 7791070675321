import axios from 'axios';
import config from './../config.js';
import {
    GET_ALL_GROUPS_SUCCESS,
    GET_ALL_GROUPS_FAILURE,

    GET_ALL_AGENTS_SUCCESS,
    GET_ALL_AGENTS_FAILURE,

    GET_ALL_EMAILS_SUCCESS,
    GET_ALL_EMAILS_FAILURE,
    PROCESS_EMAILS_SUCCESS,
    PROCESS_EMAILS_FAILURE,
    CLEAR_EMAILS
} from './type'

export const getGroups = () => {
    return function (dispatch) {
        axios.get(config.api_url + '/stuck/group')
            .then(function (response) {
                dispatch({
                    type: GET_ALL_GROUPS_SUCCESS,
                    payload: response.data.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: GET_ALL_GROUPS_FAILURE,
                    payload: error
                });
        });
    };
}
export const getAgents = ({groupId,onSuccess,onFailure}) => {
    return function (dispatch) {
        axios.get(config.api_url + `/stuck/agent?groupId=${groupId}`)
            .then(function (response) {
                dispatch({
                    type: GET_ALL_AGENTS_SUCCESS,
                    payload: response.data
                });
                onSuccess()
            })
            .catch(function (error) {
                dispatch({
                    type: GET_ALL_AGENTS_FAILURE,
                    payload: error
                });
                onFailure()
        });
    };
}

export const getEmails = ({groupName,agentName,onSuccess,onFailure}) => {
    return function (dispatch) {
        axios.get(config.api_url +`/stuck/email?groupName=${groupName}&agentName=${agentName}`)
            .then(function (response) {
                dispatch({
                    type: GET_ALL_EMAILS_SUCCESS,
                    payload: response.data
                });
                onSuccess()
            })
            .catch(function (error) {
                dispatch({
                    type: GET_ALL_EMAILS_FAILURE,
                    payload: error
                });
                onFailure()
        });
    };
}
export const processStuckEmails = ({groupId,agentId,emails,onSuccess,onFailure}) => {
    let stuckUrl = process.env.REACT_APP_STUCK_URL
    console.log(stuckUrl,"stuck url")
    return function (dispatch) {
        axios.post(stuckUrl +`stuck/emails/agentFolder`,{groupId:groupId,agentId:agentId,emails:emails})
            .then(function (response) {
                dispatch({
                    type: PROCESS_EMAILS_SUCCESS,
                    payload: response.data
                });
                onSuccess()
            })
            .catch(function (error) {
                dispatch({
                    type: PROCESS_EMAILS_FAILURE,
                    payload: error
                });
                onFailure()
        });
    };
}




export const clearEmails = () => {
    let data = {}
    return function(dispatch) {
        dispatch({
          type: CLEAR_EMAILS,
          payload:data
        })
      }
}
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader,Select,Segment } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import passwordValidator from 'password-validator';

import { Scrollbars } from 'rc-scrollbars';

import {GetAllAgent , SaveAgent , ActiveAgent ,EditAgent,ForceLogoutAgent ,DeactiveAgent} from './../action/agent';
import { GetAllGroup } from './../action/group';
import { isLoggedIn , setupAxios } from '../util';

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'name', title: 'Name' },
        { name: 'email', title: 'Email' },
        { name: 'group', title: 'Groups' },
        { name: 'status', title: 'Status' },
        { name: 'break', title: 'Break' },
        { name: 'breakReason', title: 'Break Reason' },
        { name: 'forceLogout', title: 'Force Logout'},
        { name: 'edit', title: 'Edit'},
        { name: 'activestatus', title: 'Active Status'}
      ],
      columnWidths : [
        { columnName: 'name', width: 200  },
        { columnName: 'email', width: 400 },
        { columnName: 'group', width: 200  },
        { columnName: 'status', width: 100  },
        { columnName: 'break', width: 100  },
        { columnName: 'breakReason', width: 150  },
        { columnName: 'forceLogout', width: 150 },
        { columnName: 'edit', width: 100 },
        { columnName: 'activestatus', width: 150 }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showAgentAddModal : false,
      agentName : '',
      agentEmail : '',
      agentPassword : '',
      agentLoginID:'',
      isActive : '',
      agentGroup : [],
      agentId : '',
      agentEmailError : false,
      agentNameError : false,
      agentGroupError : false,
      agentPasswordError : false,
      agentLoginIDError: false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      groups : [],
      showAgentEditModal : false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllAgent();
    this.getAgent = setInterval(() => { 
      this.setState({isUploading : false });
      setupAxios(this.props.auth.accessToken);
      this.props.GetAllAgent();
    }, 1000);

   
  }

  componentWillUnmount(){
    console.log(this.getAgent,"returnnnnnnnnn")
    clearInterval(this.getAgent);

  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });

    if(nextProps.agent.data && nextProps.agent.data.length){

      var rows = nextProps.agent.data.map((d) => {

          d.status = d.isOnline ? 'Online' : 'Offline';
          d.break = d.isBreak ? 'Yes' : 'No';
          d.breakReason = d.isBreak ? d.breakReason ? d.breakReason : '' : '';
          d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editAgent(d)}><Icon name={'edit'}></Icon></Button>;
          d.activestatus = d.isActive ? 'Active': 'Deactive';
          d.forceLogout = <Button style={{backgroundColor : "#32a852"}} onClick={() => this.agentForceLogout(d)} >Logout</Button>;
          d.group = d.groupName ? d.groupName.join(): <p>-</p> ;
          return(d)

      });

      this.setState({ rows });
    }

    if(nextProps.agent.isAgentSave){
      this.setState({ successMessage : "Agent Added Successfully." , successModal : true });
    }else if(nextProps.agent.agentSaveErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentSaveErrorMessage, errorModal : true });
    }

    if(nextProps.agent.isAgentDelete){
      this.setState({ successMessage : "Agent Deleted Successfully." , successModal : true });
    }else if(nextProps.agent.agentDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.agent.isAgentActive){
      this.setState({ successMessage : "Agent Active Successfully." , successModal : true });
    }else if(nextProps.agent.agentActiveErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentActiveErrorMessage, errorModal : true });
    }

    if(nextProps.agent.isAgentDeactive){
      this.setState({ successMessage : "Agent Deactive Successfully." , successModal : true });
    }else if(nextProps.agent.agentDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentDeactiveErrorMessage, errorModal : true });
    }

    if(nextProps.agent.isAgentEdit){
      this.setState({ successMessage : "Agent Updated Successfully." , successModal : true });
    }else if(nextProps.agent.agentEditErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentEditErrorMessage, errorModal : true });
    }

    if(nextProps.agent.isAgentForceLogout){
      this.setState({ successMessage : "Agent Logout Successfully." , successModal : true });
    }else if(nextProps.agent.agentForceLogoutErrorMessage){
      this.setState({ errorMessage : nextProps.agent.agentForceLogoutErrorMessage, errorModal : true });
    }

    if(nextProps.group.data && nextProps.group.data.length){

      var groups = nextProps.group.data.map((d,index) =>{
        return (
          {
            key : index,
            text : d.name,
            value : d.id
          }
        )
      })

      this.setState({ groups });
    }

  }

  activeAgent = (agentId) =>{
    this.setState({isUploading : true });
    this.props.ActiveAgent( agentId );
  }

  deactiveAgent = (agentId) =>{

    this.setState({isUploading : true });
    this.props.DeactiveAgent( agentId );
  }

  agentForceLogout = (agent) =>{
    this.setState({isUploading : true });
    this.props.ForceLogoutAgent({ email : agent.email });
  }

  editAgent = (agent) =>{
    this.props.GetAllGroup();
    this.setState({  agentId : agent.id , agentEmail : agent.email , agentName : agent.name , agentGroup : agent.groupIds , agentLoginID: agent.loginID, agentPassword : agent.password , showAgentEditModal : true , isActive : agent.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  addAgentModal = () =>{
    this.setState({ showAgentAddModal : true , agentName : '' , agentEmail : '' , agentLoginID: '', agentGroup : [] , agentPassword : '' });
    this.props.GetAllGroup();
  }

  handleEmailChange = (email) =>{
    this.setState({ agentEmail : email.target.value , agentEmailError : false });
  }

  handleLoginIDChange = (loginID) =>{
    this.setState({ agentLoginID : loginID.target.value , agentLoginIDError : false });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  handlePasswordChange = (password) =>{

    var schema = new passwordValidator();
    schema
    .is().min(8)
    .is().max(24)
    .has().uppercase()
    .has().lowercase()        
    .has().digits(1) 
    .has().not().spaces()
    .has().symbols()  
    
    if( password.target.value.length <= 24 ){

      if(schema.validate(password.target.value)){

        this.setState({ agentPassword : password.target.value , agentPasswordError : false });
  
      }else{
  
        this.setState({ agentPassword : password.target.value , agentPasswordError : true });
  
      }

    }

  }

  handleNameChange = (name) =>{
    this.setState({ agentName : name.target.value , agentNameError : false });
  }

  selectGroup = (e,data) =>{
    this.setState({ agentGroup : data.value , agentGroupError : false });
  }

  saveAgent = () =>{

    var agentEmail = this.state.agentEmail;
    var agentName = this.state.agentName;
    var agentGroup = this.state.agentGroup;
    var agentPassword = this.state.agentPassword;
    var agentLoginID = this.state.agentLoginID;



    var schema = new passwordValidator();
    schema
    .is().min(8)
    .is().max(24)
    .has().uppercase()
    .has().lowercase()        
    .has().digits(1) 
    .has().not().spaces()
    .has().symbols()  

    var checkPassword = schema.validate(agentPassword);

    console.log( agentEmail,agentLoginID , checkPassword ,agentGroup,agentName,  "ssssssssssssssssssssssssss" )

    if( agentEmail && agentName && agentGroup.length &&  checkPassword  && agentLoginID){
      this.setState({isUploading : true });
      this.props.SaveAgent({ email : agentEmail , name : agentName , group : agentGroup ,  loginID :agentLoginID, password : agentPassword });
    }else{

      if(!agentEmail){
        this.setState({agentEmailError : true });
      }
      if(!agentName){
        this.setState({agentNameError : true });
      }
      if(!agentGroup.length ){
        this.setState({agentGroupError : true });
      }
      if(!checkPassword){
        this.setState({agentPasswordError : true });
      }
      if(!agentLoginID){
        this.setState({agentLoginIDError : true });
      }
    }

  }

  updateAgent = () =>{

    var agentEmail = this.state.agentEmail;
    var agentName = this.state.agentName;
    var agentGroup = this.state.agentGroup;
    // var agentPassword = this.state.agentPassword;
    var agentLoginID = this.state.agentLoginID;

    var schema = new passwordValidator();
    schema
    .is().min(8)
    .is().max(24)
    .has().uppercase()
    .has().lowercase()        
    .has().digits(1) 
    .has().not().spaces()
    .has().symbols()  

    // var checkPassword =  schema.validate(agentPassword) ;

    var agentPassword = null;
    var checkPassword = true;

    if(agentEmail && agentName && agentGroup.length && checkPassword && agentLoginID ){
      this.setState({isUploading : true });
      this.props.EditAgent({ email : agentEmail , name : agentName , group : agentGroup  , loginID: agentLoginID, password : agentPassword , agentId : this.state.agentId });
    }else{

      if(!agentEmail){
        this.setState({agentEmailError : true });
      }
      if(!agentName){
        this.setState({agentNameError : true });
      }
      if(!agentGroup.length ){
        this.setState({agentGroupError : true });
      }
      if(!checkPassword ){
        this.setState({agentPasswordError : true });
      }
      if(!agentLoginID ){
        this.setState({agentLoginIDError : true });
      }
    }

  }

  closeAgentCloseModal = () =>{
    this.setState({ showAgentAddModal : false });
  }

  agentEditCloseModal = () =>{
    this.setState({ showAgentEditModal : false });
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showAgentAddModal : false , showAgentEditModal : false });
    this.props.GetAllAgent();
  
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  render() {
    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addAgentModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" />Add Agent
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            // defaultSorting={[
            //   { columnName: "name", direction: "desc" },
            //   { columnName: "email", direction: "desc" },
            //   { columnName: "group", direction: "desc" },
            //   { columnName: "status", direction: "desc" },
            //   { columnName: "break", direction: "desc" },
            //   { columnName: "activestatus", direction: "asc" },
            // ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
        <MDBModal isOpen={this.state.showAgentAddModal} centered size="lg">
            <MDBModalHeader>
                Add Agent
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Name : </b></label>
                <Input onChange={this.handleNameChange}  placeholder='Enter the Name' style={this.state.agentNameError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b>Login ID :</b></label>
                <Input onChange={this.handleLoginIDChange}  placeholder='Enter the Login ID' style={this.state.agentLoginIDError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                <label><b>Email  :</b></label>
                <Input onChange={this.handleEmailChange}  placeholder='Enter the Email' style={this.state.agentEmailError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 
                
                <label><b>Password  :</b></label>
                <Input onChange={this.handlePasswordChange} value={this.state.agentPassword}   placeholder='Enter the Password' style={this.state.agentPasswordError?{border: '1px solid red'}:{}}></Input>
                <br/>
                <label style={this.state.agentPasswordError ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number in your password.</label>
                <br/><br/> 
                
                <label><b>Group  :</b></label>
                <Select multiple  search value={this.state.residentialStatus} options={this.state.groups} onChange={this.selectGroup} placeholder='Select Group' style={this.state.agentGroupError?{border: '1px solid red'}:{}}></Select>



            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeAgentCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveAgent}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showAgentEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Agent
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Name : </b></label>
                <Input onChange={this.handleNameChange} value={this.state.agentName} placeholder='Enter the Name' style={this.state.agentNameError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>    

                <label><b>Login ID :</b></label>
                <Input onChange={this.handleLoginIDChange}  value={this.state.agentLoginID}  placeholder='Enter the Login ID' style={this.state.agentLoginIDError?{border: '1px solid red'}:{}}></Input>
                <br/><br/>                    
                
                <label><b>Email  :</b></label>
                <Input onChange={this.handleEmailChange} value={this.state.agentEmail}   placeholder='Enter the Email' style={this.state.agentEmailError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                {/* <label><b>Password  :</b></label>
                <Input onChange={this.handlePasswordChange} value=''   placeholder='Enter the Password' style={this.state.agentPasswordError?{border: '1px solid red'}:{}}></Input>
                <br/> */}
                {/* <label style={this.state.agentPasswordError ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                <br/><br/>  */}
                
                <label><b>Group  :</b></label>
                <Select multiple  search value={this.state.agentGroup} options={this.state.groups} onChange={this.selectGroup} placeholder='Select Group' style={this.state.agentGroupError?{border: '1px solid red'}:{}}></Select>
                


            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.agentEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateAgent}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactiveAgent(this.state.agentId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.activeAgent(this.state.agentId) }>Active</Button>
              }

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      agent: state.agent,
      group: state.group
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
 
    GetAllAgent,
    SaveAgent,
    GetAllGroup,
    ActiveAgent,
    EditAgent,
    ForceLogoutAgent,
    DeactiveAgent
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agent));

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION';
export const LOGIN_FAILURE_ACTION = 'LOGIN_FAILURE_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const GET_ALL_AGENT_SUCCESS = 'GET_ALL_AGENT_SUCCESS';
export const GET_ALL_AGENT_FAILURE = 'GET_ALL_AGENT_FAILURE';
export const SAVE_AGENT_SUCCESS = 'SAVE_AGENT_SUCCESS';
export const SAVE_AGENT_FAILURE = 'SAVE_AGENT_FAILURE';
export const GET_ALL_GROUP_SUCCESS = 'GET_ALL_GROUP_SUCCESS';
export const GET_ALL_GROUP_FAILURE = 'GET_ALL_GROUP_FAILURE';
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS';
export const DELETE_AGENT_FAILURE = 'DELETE_AGENT_FAILURE';
export const ACTIVE_AGENT_SUCCESS = 'ACTIVE_AGENT_SUCCESS';
export const ACTIVE_AGENT_FAILURE = 'ACTIVE_AGENT_FAILURE';
export const DEACTIVE_AGENT_SUCCESS = 'DEACTIVE_AGENT_SUCCESS';
export const DEACTIVE_AGENT_FAILURE = 'DEACTIVE_AGENT_FAILURE';
export const EDIT_AGENT_SUCCESS = 'EDIT_AGENT_SUCCESS';
export const ACTIVE_GROUP_SUCCESS = 'ACTIVE_GROUP_SUCCESS';
export const ACTIVE_GROUP_FAILURE = 'ACTIVE_GROUP_FAILURE';
export const DEACTIVE_GROUP_SUCCESS = 'DEACTIVE_GROUP_SUCCESS';
export const DEACTIVE_GROUP_FAILURE = 'DEACTIVE_GROUP_FAILURE';
export const EDIT_AGENT_FAILURE = 'EDIT_AGENT_FAILURE';
export const FORCELOGOUT_AGENT_SUCCESS = 'FORCELOGOUT_AGENT_SUCCESS';
export const FORCELOGOUT_AGENT_FAILURE = 'FORCELOGOUT_AGENT_FAILURE';
export const SAVE_GROUP_SUCCESS = 'SAVE_GROUP_SUCCESS';
export const SAVE_GROUP_FAILURE = 'SAVE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';
export const GET_ALL_SHIFT_CONFIG_SUCCESS = 'GET_ALL_SHIFT_CONFIG_SUCCESS';
export const GET_ALL_SHIFT_CONFIG_FAILURE = 'GET_ALL_SHIFT_CONFIG_FAILURE';
export const SAVE_SHIFT_CONFIG_SUCCESS = 'SAVE_SHIFT_CONFIG_SUCCESS';
export const SAVE_SHIFT_CONFIG_FAILURE = 'SAVE_SHIFT_CONFIG_FAILURE';
export const DELETE_SHIFT_CONFIG_SUCCESS = 'DELETE_SHIFT_CONFIG_SUCCESS';
export const DELETE_SHIFT_CONFIG_FAILURE = 'DELETE_SHIFT_CONFIG_FAILURE';
export const EDIT_SHIFT_CONFIG_SUCCESS = 'EDIT_SHIFT_CONFIG_SUCCESS';
export const EDIT_SHIFT_CONFIG_FAILURE = 'EDIT_SHIFT_CONFIG_FAILURE';
export const GET_ALL_BREAK_CONFIG_SUCCESS = 'GET_ALL_BREAK_CONFIG_SUCCESS';
export const GET_ALL_BREAK_CONFIG_FAILURE = 'GET_ALL_BREAK_CONFIG_FAILURE';
export const SAVE_BREAK_CONFIG_SUCCESS = 'SAVE_BREAK_CONFIG_SUCCESS';
export const SAVE_BREAK_CONFIG_FAILURE = 'SAVE_BREAK_CONFIG_FAILURE';
export const DELETE_BREAK_CONFIG_SUCCESS = 'DELETE_BREAK_CONFIG_SUCCESS';
export const DELETE_BREAK_CONFIG_FAILURE = 'DELETE_BREAK_CONFIG_FAILURE';
export const EDIT_BREAK_CONFIG_SUCCESS = 'EDIT_BREAK_CONFIG_SUCCESS';
export const EDIT_BREAK_CONFIG_FAILURE = 'EDIT_BREAK_CONFIG_FAILURE';
export const GET_ALL_GENERALISSUE_CONFIG_SUCCESS = 'GET_ALL_GENERALISSUE_CONFIG_SUCCESS';
export const GET_ALL_GENERALISSUE_CONFIG_FAILURE = 'GET_ALL_GENERALISSUE_CONFIG_FAILURE';
export const SAVE_GENERALISSUE_CONFIG_SUCCESS = 'SAVE_GENERALISSUE_CONFIG_SUCCESS';
export const SAVE_GENERALISSUE_CONFIG_FAILURE = 'SAVE_GENERALISSUE_CONFIG_FAILURE';
export const DELETE_GENERALISSUE_CONFIG_SUCCESS = 'DELETE_GENERALISSUE_CONFIG_SUCCESS';
export const DELETE_GENERALISSUE_CONFIG_FAILURE = 'DELETE_GENERALISSUE_CONFIG_FAILURE';
export const EDIT_GENERALISSUE_CONFIG_SUCCESS = 'EDIT_GENERALISSUE_CONFIG_SUCCESS';
export const EDIT_GENERALISSUE_CONFIG_FAILURE = 'EDIT_GENERALISSUE_CONFIG_FAILURE';
export const GET_ALL_REPORT_CONFIG_SUCCESS = 'GET_ALL_REPORT_CONFIG_SUCCESS';
export const GET_ALL_REPORT_CONFIG_FAILURE = 'GET_ALL_REPORT_CONFIG_FAILURE';
export const SAVE_REPORT_CONFIG_SUCCESS = 'SAVE_REPORT_CONFIG_SUCCESS';
export const SAVE_REPORT_CONFIG_FAILURE = 'SAVE_REPORT_CONFIG_FAILURE';
export const DELETE_REPORT_CONFIG_SUCCESS = 'DELETE_REPORT_CONFIG_SUCCESS';
export const DELETE_REPORT_CONFIG_FAILURE = 'DELETE_REPORT_CONFIG_FAILURE';
export const EDIT_REPORT_CONFIG_SUCCESS = 'EDIT_REPORT_CONFIG_SUCCESS';
export const EDIT_REPORT_CONFIG_FAILURE = 'EDIT_REPORT_CONFIG_FAILURE';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAILURE = 'USER_PASSWORD_CHANGE_FAILURE';
export const ACTIVE_SHIFT_SUCCESS = 'ACTIVE_SHIFT_SUCCESS';
export const ACTIVE_SHIFT_FAILURE = 'ACTIVE_SHIFT_FAILURE';
export const DEACTIVE_SHIFT_SUCCESS = 'DEACTIVE_SHIFT_SUCCESS';
export const DEACTIVE_SHIFT_FAILURE = 'DEACTIVE_SHIFT_FAILURE';
export const ACTIVE_BREAK_SUCCESS = 'ACTIVE_BREAK_SUCCESS';
export const ACTIVE_BREAK_FAILURE = 'ACTIVE_BREAK_FAILURE';
export const DEACTIVE_BREAK_SUCCESS = 'DEACTIVE_BREAK_SUCCESS';
export const DEACTIVE_BREAK_FAILURE = 'DEACTIVE_BREAK_FAILURE';
export const ACTIVE_GENERALISSUE_SUCCESS = 'ACTIVE_GENERALISSUE_SUCCESS';
export const ACTIVE_GENERALISSUE_FAILURE = 'ACTIVE_GENERALISSUE_FAILURE';
export const DEACTIVE_GENERALISSUE_SUCCESS = 'DEACTIVE_GENERALISSUE_SUCCESS';
export const DEACTIVE_GENERALISSUE_FAILURE = 'DEACTIVE_GENERALISSUE_FAILURE';
export const ACTIVE_REPORT_CONFIG_SUCCESS = 'ACTIVE_REPORT_CONFIG_SUCCESS';
export const ACTIVE_REPORT_CONFIG_FAILURE = 'ACTIVE_REPORT_CONFIG_FAILURE';
export const DEACTIVE_REPORT_CONFIG_SUCCESS = 'DEACTIVE_REPORT_CONFIG_SUCCESS';
export const DEACTIVE_REPORT_CONFIG_FAILURE = 'DEACTIVE_REPORT_CONFIG_FAILURE';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const GET_ALL_ADMIN_USER_SUCCESS = 'GET_ALL_ADMIN_USER_SUCCESS';
export const GET_ALL_ADMIN_USER_FAILURE = 'GET_ALL_ADMIN_USER_FAILURE';
export const EDIT_ADMIN_USER_SUCCESS = 'EDIT_ADMIN_USER_SUCCESS';
export const EDIT_ADMIN_USER_FAILURE = 'EDIT_ADMIN_USER_FAILURE';
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS"
export const GET_ALL_GROUPS_FAILURE = "GET_ALL_GROUPS_FAILURE"

export const GET_ALL_AGENTS_SUCCESS = 'GET_ALL_AGENTS_SUCCESS'
export const GET_ALL_AGENTS_FAILURE = 'GET_ALL_AGENTS_FAILURE'

export const GET_ALL_EMAILS_SUCCESS = 'GET_ALL_EMAILS_SUCCESS'
export const GET_ALL_EMAILS_FAILURE = 'GET_ALL_EMAILS_FAILURE'
export const PROCESS_EMAILS_SUCCESS = 'PROCESS_EMAILS_SUCCESS'
export const PROCESS_EMAILS_FAILURE = 'PROCESS_EMAILS_FAILURE'
export const CLEAR_EMAILS = 'CLEAR_EMAILS'
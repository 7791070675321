import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader,Segment } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { Scrollbars } from 'rc-scrollbars';

import { GetAllGroup , SaveGroup , EditGroup , DeleteGroup , ActiveGroup , DeactiveGroup } from './../action/group';
import { isLoggedIn , setupAxios } from '../util';

class Group extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'name', title: 'Name' },
        { name: 'SLA', title: 'SLA' },
        { name: 'maxEmail', title: 'Maximum no of Email' },
        { name: 'edit', title: 'Edit'},
        { name: 'status', title: 'Status'},
      ],
      columnWidths : [
        { columnName: 'name', width: 300  },
        { columnName: 'SLA', width: 100 },
        { columnName: 'maxEmail', width: 300 },
        { columnName: 'edit', width: 100  },
        { columnName: 'status', width: 200  }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showGroupAddModal : false,
      groupName : '',
      groupSLA : '',
      groupMaxEmail : '',
      isActive : '',
      groupNameError : false,
      groupSLAError : false,
      groupMaxEmailError: false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      showGroupEditModal : false,
      groupId : '',
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllGroup();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : false });

    if(nextProps.group.data && nextProps.group.data.length){

      var rows = nextProps.group.data.map((d) => {

          d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editGroup(d)}><Icon name={'edit'}></Icon></Button>
          d.status = d.isActive ? 'Active' : 'Deactive';
          return(d)
        
      });

      this.setState({ rows });
    }else{
      this.setState({ rows : [] });
    }

    if(nextProps.group.isGroupSave){
      this.setState({ successMessage : "Group Added Successfully." , successModal : true });
    }else if(nextProps.group.groupSaveErrorMessage){
      this.setState({ errorMessage : nextProps.group.groupSaveErrorMessage, errorModal : true });
    }
    
    if(nextProps.group.isGroupDelete){
      this.setState({ successMessage : "Group Deleted Successfully." , successModal : true });
    }else if(nextProps.group.groupDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.group.groupDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.group.isGroupEdit){
      this.setState({ successMessage : "Group Updated Successfully." , successModal : true });
    }else if(nextProps.group.groupEditErrorMessage){
      this.setState({ errorMessage : nextProps.group.groupEditErrorMessage, errorModal : true });
    }

    if(nextProps.group.isGroupActive){
      this.setState({ successMessage : "Group Active Successfully." , successModal : true });
    }else if(nextProps.group.groupActiveErrorMessage){
      this.setState({ errorMessage : nextProps.group.groupActiveErrorMessage, errorModal : true });
    }

    if(nextProps.group.isGroupDeactive){
      this.setState({ successMessage : "Group Deactive Successfully." , successModal : true });
    }else if(nextProps.group.groupDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.group.groupDeactiveErrorMessage, errorModal : true });
    }

  }

  deleteGroup = (group) =>{
    this.setState({isUploading : true });
    this.props.DeleteGroup( group.id );
  }


  editGroup = (group) =>{
    this.setState({ groupName : group.name , groupSLA : group.SLA ,  groupMaxEmail : group.maxEmail, groupId : group.id ,showGroupEditModal : true , isActive : group.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  addGroupModal = () =>{
    this.setState({ showGroupAddModal : true });
  }

  handleMaxEmailChange = (maxEmail) =>{

    const re = /^[0-9\b]+$/;

    if( ( re.test(maxEmail.target.value) || maxEmail.target.value === "" ) && maxEmail.target.value.length <= 4 ){

      this.setState({ groupMaxEmail : maxEmail.target.value , groupMaxEmailError : false });

    }

  }

  handleSLAChange = (sla) =>{

    const re = /^[0-9\b]+$/;

    if( ( re.test(sla.target.value) || sla.target.value === "" ) && sla.target.value.length <= 4 ){

      this.setState({ groupSLA : sla.target.value , groupSLAError : false });

    }

  }

  handleNameChange = (name) =>{
    this.setState({ groupName : name.target.value , groupNameError : false });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  saveGroup = () =>{

    var groupName = this.state.groupName;
    var groupSLA = this.state.groupSLA;
    var groupMaxEmail = this.state.groupMaxEmail;

    if(groupName && groupSLA  ){
      this.setState({isUploading : true });
      this.props.SaveGroup({ name : groupName , sla : groupSLA, maxEmail : groupMaxEmail });
    }else{

      if(!groupName){
        this.setState({groupNameError : true });
      }
      if(!groupSLA){
        this.setState({groupSLAError : true });
      }
      if(!groupMaxEmail){
        this.setState({groupMaxEmailError : true });
      }
    }

  }

  updateGroup = () =>{

    var groupName = this.state.groupName;
    var groupSLA = this.state.groupSLA;
    var groupMaxEmail = this.state.groupMaxEmail;

    if(groupName && groupSLA  ){
      this.setState({isUploading : true });
      this.props.EditGroup({ name : groupName , sla : groupSLA , groupId : this.state.groupId, maxEmail : groupMaxEmail  });
    }else{

      if(!groupName){
        this.setState({groupNameError : true });
      }
      if(!groupSLA){
        this.setState({groupSLAError : true });
      }
      if(!groupMaxEmail){
        this.setState({groupMaxEmailError : true });
      }
    }

  }

  closeGroupCloseModal = () =>{
    this.setState({ showGroupAddModal : false });
  }

  groupEditCloseModal = () =>{
    this.setState({ showGroupEditModal : false });
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showGroupAddModal : false , showGroupEditModal : false });
    this.props.GetAllGroup();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  activeGroup = (groupId) =>{
    this.setState({isUploading : true });
    this.props.ActiveGroup( groupId );
  }

  deactiveGroup = (groupId) =>{
    this.setState({isUploading : true });
    this.props.DeactiveGroup( groupId );
  }

  render() {

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addGroupModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add Group
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            defaultSorting={[
              { columnName: "name", direction: "desc" },
              { columnName: "sla", direction: "desc" },
              { columnName: "maxEmail", direction: "desc" },
            ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
        <MDBModal isOpen={this.state.showGroupAddModal} centered size="lg">
            <MDBModalHeader>
                Add Groups
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Name : </b></label>
                <Input onChange={this.handleNameChange} value={this.state.groupName} placeholder='Enter the Name' style={this.state.groupNameError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b>SLA  :</b></label>
                <Input onChange={this.handleSLAChange} value={this.state.groupSLA}  placeholder='Enter the SLA' style={this.state.groupSLAError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                <label><b>Max no of Emails :</b></label>
                <Input onChange={this.handleMaxEmailChange} value={this.state.groupMaxEmail}  placeholder='Enter the Maximum no of email' style={this.state.groupMaxEmailError?{border: '1px solid red'}:{}}></Input>
                <br/><br/>
                
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeGroupCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveGroup}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showGroupEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Group
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Name : </b></label>
                <Input onChange={this.handleNameChange} value={this.state.groupName} placeholder='Enter the Name' style={this.state.groupNameError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b>SLA  :</b></label>
                <Input onChange={this.handleSLAChange} value={this.state.groupSLA}   placeholder='Enter the SLA' style={this.state.groupSLAError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                <label><b>Max no of Emails :</b></label>
                <Input onChange={this.handleMaxEmailChange} value={this.state.groupMaxEmail}   placeholder='Enter the Maximum no of email' style={this.state.groupMaxEmailError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 
                
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.groupEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateGroup}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactiveGroup(this.state.groupId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.activeGroup(this.state.groupId) }>Active</Button>
              }

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      group: state.group
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAllGroup,
    SaveGroup,
    EditGroup,
    DeleteGroup,
    ActiveGroup,
    DeactiveGroup
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Group));
import axios from 'axios';

import {
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILURE
} from './type.js';

import config from './../config.js';

export const GetServices = () =>{
    return function (dispatch) {
        axios.get(config.api_url + '/service/get')
            .then(function (response) {
                dispatch({
                    type: GET_SERVICES_SUCCESS,
                    payload: response.data.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: GET_SERVICES_FAILURE,
                    payload: error
                });
        });
    };
}

import axios from 'axios';

import {
    GET_ALL_AGENT_SUCCESS,
    GET_ALL_AGENT_FAILURE,
    SAVE_AGENT_SUCCESS,
    SAVE_AGENT_FAILURE,
    EDIT_AGENT_SUCCESS,
    EDIT_AGENT_FAILURE,
    FORCELOGOUT_AGENT_SUCCESS,
    FORCELOGOUT_AGENT_FAILURE,
    ACTIVE_AGENT_SUCCESS,
    ACTIVE_AGENT_FAILURE,
    DEACTIVE_AGENT_SUCCESS,
    DEACTIVE_AGENT_FAILURE
} from './type.js';

import config from '../config.js';

export const GetAllAgent = () =>{

    return function (dispatch) {

        axios.get(config.api_url+"/agent/get").then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_AGENT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_AGENT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_AGENT_FAILURE,
                    payload: error
                });
            });

    };
}

export const SaveAgent = (data) =>{

    return function (dispatch) {

        axios.post(config.api_url+"/agent/add" , data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_AGENT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_AGENT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_AGENT_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditAgent = (data) =>{

    return function (dispatch) {

        axios.post( config.api_url+"/agent/edit" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: EDIT_AGENT_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: EDIT_AGENT_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: EDIT_AGENT_FAILURE,
                payload: error
            });
        });
    };
}

export const ActiveAgent = (agentId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/agent/active" , {agentId : agentId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_AGENT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_AGENT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_AGENT_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeactiveAgent = (agentId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/agent/deactive" , {agentId : agentId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_AGENT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_AGENT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_AGENT_FAILURE,
                    payload: error
                });
            });
    };
}

export const ForceLogoutAgent = (data) =>{
    return function (dispatch) {

        axios.post(config.api_url+"/agent/forceLogout" , data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: FORCELOGOUT_AGENT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: FORCELOGOUT_AGENT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: FORCELOGOUT_AGENT_FAILURE,
                    payload: error
                });
            });
    };
}


import {
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_AGENTS_SUCCESS,
  GET_ALL_EMAILS_SUCCESS,
  CLEAR_EMAILS
} from "../action/type";

const INTIAL_STATE = {
  groups: [],
  agents: [],
  emails: [],
};

export default function (state , action) {
  if (typeof state === 'undefined') {
    state = INTIAL_STATE
}
  switch (action.type) {
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      };
    case GET_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
      };
    case GET_ALL_EMAILS_SUCCESS:
      return {
        ...state,
        emails: action.payload,
      };
    case CLEAR_EMAILS:
        return {
            ...state,
            emails: [],
          };  
    default:
      return state;
  }
}

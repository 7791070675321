import axios from 'axios';

import {
   GET_ALL_SHIFT_CONFIG_SUCCESS,
   GET_ALL_SHIFT_CONFIG_FAILURE,
   SAVE_SHIFT_CONFIG_SUCCESS,
   SAVE_SHIFT_CONFIG_FAILURE,
   EDIT_SHIFT_CONFIG_SUCCESS,
   EDIT_SHIFT_CONFIG_FAILURE,
   DELETE_SHIFT_CONFIG_SUCCESS,
   DELETE_SHIFT_CONFIG_FAILURE,
   ACTIVE_SHIFT_SUCCESS,
   ACTIVE_SHIFT_FAILURE,
   DEACTIVE_SHIFT_SUCCESS,
   DEACTIVE_SHIFT_FAILURE
} from './type.js';

import config from '../config.js';

export const GetAllShiftConfig = () =>{
    return function (dispatch) {

        axios.get( config.api_url+"/config/shift/get", ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_SHIFT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_SHIFT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_SHIFT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const SaveShift = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/shift/add", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_SHIFT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_SHIFT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_SHIFT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditShift = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/shift/edit", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: EDIT_SHIFT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: EDIT_SHIFT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: EDIT_SHIFT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeleteShift = (shiftId) =>{
    return function (dispatch) {

        axios.delete( config.api_url+"/config/shift/delete/"+shiftId ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DELETE_SHIFT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DELETE_SHIFT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DELETE_SHIFT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const Active = (shiftId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/shift/active" , {shiftId : shiftId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_SHIFT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_SHIFT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_SHIFT_FAILURE,
                    payload: error
                });
            });
    };
}

export const Deactive = (shiftId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/shift/deactive" , {shiftId : shiftId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_SHIFT_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_SHIFT_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_SHIFT_FAILURE,
                    payload: error
                });
            });
    };
}


import {
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function ( state  , action  ) {
    if (typeof state === 'undefined') {
        state = INIT_STATE
    }
    switch (action.type) {
        
        case RESET_PASSWORD_SUCCESS:
            state = {
                isResetPassword : true
            } ;
            return state;
        case RESET_PASSWORD_FAILURE:
            state = {
                resetPasswordErrorMessage : action.payload.message,
                isResetPassword : false
            } ;
            return state;
        default :
            return state;
    }
}
import {
    GET_ALL_SHIFT_CONFIG_SUCCESS,
    GET_ALL_SHIFT_CONFIG_FAILURE,   
    SAVE_SHIFT_CONFIG_SUCCESS,
    SAVE_SHIFT_CONFIG_FAILURE,
    EDIT_SHIFT_CONFIG_SUCCESS,
    EDIT_SHIFT_CONFIG_FAILURE,
    DELETE_SHIFT_CONFIG_SUCCESS,
    DELETE_SHIFT_CONFIG_FAILURE,
    ACTIVE_SHIFT_SUCCESS,
    ACTIVE_SHIFT_FAILURE,
    DEACTIVE_SHIFT_SUCCESS,
    DEACTIVE_SHIFT_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state , action ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_SHIFT_CONFIG_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_SHIFT_CONFIG_FAILURE:
            state = {
                isShiftGetError : true,
                shiftGetErrorMessage : action.payload.message
            }
            return state;
        case SAVE_SHIFT_CONFIG_SUCCESS:
            state = {
                isShiftSave : true
            };
            return state;

        case SAVE_SHIFT_CONFIG_FAILURE:
            state = {
                isShiftSave : false,
                shiftSaveErrorMessage : action.payload.message
            }
            return state;
        case DELETE_SHIFT_CONFIG_SUCCESS:
            state = {
                isShiftDelete : true
            };
            return state;

        case DELETE_SHIFT_CONFIG_FAILURE:
            state = {
                isShiftDelete : false,
                shiftDeleteErrorMessage : action.payload.message
            }
            return state;

        case EDIT_SHIFT_CONFIG_SUCCESS:
            state = {
                isShiftEdit : true
            };
            return state;

        case EDIT_SHIFT_CONFIG_FAILURE:
            state = {
                isShiftEdit : false,
                shiftEditErrorMessage : action.payload.message
            }
            return state;
        case ACTIVE_SHIFT_SUCCESS:
            state = {
                isShiftActive : true
            };
            return state;

        case ACTIVE_SHIFT_FAILURE:
            state = {
                isShiftActive : false,
                shiftActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_SHIFT_SUCCESS:
            state = {
                isShiftDeactive : true
            };
            return state;

        case DEACTIVE_SHIFT_FAILURE:
            state = {
                isShiftDeactive : false,
                shiftDeactiveErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import axios from 'axios';

import {
   GET_ALL_BREAK_CONFIG_SUCCESS,
   GET_ALL_BREAK_CONFIG_FAILURE,
   SAVE_BREAK_CONFIG_SUCCESS,
   SAVE_BREAK_CONFIG_FAILURE,
   EDIT_BREAK_CONFIG_SUCCESS,
   EDIT_BREAK_CONFIG_FAILURE,
   DELETE_BREAK_CONFIG_SUCCESS,
   DELETE_BREAK_CONFIG_FAILURE,
   ACTIVE_BREAK_SUCCESS,
   ACTIVE_BREAK_FAILURE,
   DEACTIVE_BREAK_SUCCESS,
   DEACTIVE_BREAK_FAILURE
} from './type.js';

import config from '../config.js';

export const GetAllBreakConfig = () =>{
    return function (dispatch) {

        axios.get( config.api_url+"/config/breakReason/get" ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_BREAK_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_BREAK_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_BREAK_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const SaveBreak = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/breakReason/add", data ).then(function (response) {

                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_BREAK_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_BREAK_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_BREAK_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditBreak = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/breakReason/edit", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: EDIT_BREAK_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: EDIT_BREAK_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: EDIT_BREAK_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeleteBreak = (breakId) =>{
    return function (dispatch) {

        axios.delete( config.api_url+"/config/breakReason/delete/"+breakId ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DELETE_BREAK_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DELETE_BREAK_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DELETE_BREAK_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const Active = (breakConfigId) =>{

    return function (dispatch) {

        axios.post( config.api_url+"/config/breakReason/active" , {breakId : breakConfigId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_BREAK_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_BREAK_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_BREAK_FAILURE,
                    payload: error
                });
            });
    };
}

export const Deactive = (breakConfigId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/breakReason/deactive" , {breakId : breakConfigId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_BREAK_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_BREAK_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_BREAK_FAILURE,
                    payload: error
                });
            });
    };
}

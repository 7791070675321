import React, { useState, useEffect } from "react";
import {
  Icon,
  Dimmer,
  Loader,
  Segment,
  Button,
  Dropdown,
  Grid,
  Label,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isLoggedIn } from "../util";
import { withRouter } from "react-router";
import {
  getAgents,
  getEmails,
  processStuckEmails,
  clearEmails,
} from "../action/ProcessStuck";
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
  SearchState,
} from "@devexpress/dx-react-grid";

import {
  Grid as Gridded,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

const ProcessStuckEmails = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupName, setGroupName] = useState("");
  const [Agent, setAgent] = useState([]);
  const [agentName, setAgentName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const [listOfEmails, setListOfEmails] = useState([]);
  const [emailsNotFound, setEmailsNotFound] = useState({});
  const [columns, setColumns] = useState([
    { name: "mailFrom", title: "Mail From" },
    { name: "subject", title: "Subject" },
    { name: "receivedTime", title: "Recived TIme" },
  ]);
  const [rows, setRows] = useState([]);
  const [LoaderText, seLoaderText] = useState("");
  const [selectionRows, setSelection] = useState([]);
  const [pageSizes] = useState([5, 10, 15, 0]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!isLoggedIn(props.auth)) {
      props.history.push(`/login`);
      console.log(setColumns)
    }
  }, []);
  useEffect(() => {
    if (props.stuckEmails.groups.length !== 0) {
      let groups1 = [
        {
          key: 0,
          text: "",
          value: "",
        },
      ];
      props.stuckEmails.groups.map((data) => {
        groups1.push({
          key: data.id,
          text: data.name,
          value: data.id,
        });
      });
      setGroups([...groups1]);
    }
  }, [props.stuckEmails.groups]);
  useEffect(() => {
    if (Object.keys(props.stuckEmails.agents).length !== 0) {
      let agent = [
        {
          key: 0,
          text: "",
          value: "",
        },
      ];
      if (props.stuckEmails.agents.statusCode === 200) {
        props.stuckEmails.agents.data.map((data) => {
          agent.push({
            key: data.id,
            text: data.name,
            value: data.id,
          });
        });
      } else if (props.stuckEmails.agents.statusCode === 201) {
        // agent.push({
        //   key: 0,
        //   text: props.stuckEmails.agents.message,
        //   value: props.stuckEmails.agents.message,
        // });
      } else {
        return;
      }
      setAgent([...agent]);
    }
  }, [props.stuckEmails.agents]);
  useEffect(() => {
    if (Object.keys(props.stuckEmails.emails).length !== 0) {
      if (props.stuckEmails.emails.statusCode === 200) {
        setListOfEmails(props.stuckEmails.emails.data);
        var rowsFromed = props.stuckEmails.emails.data.map((d, index) => {
          d.id = index;
          // d.mailFrom = d.mailFrom;
          // d.subject = d.subject;
          // d.receivedTime = d.receivedTime;
          return d;
        });
        setRows([...rowsFromed]);
      } else if (props.stuckEmails.emails.statusCode === 201) {
        setEmailsNotFound(props.stuckEmails.emails);
        console.log(emailsNotFound)
      } else {
        return;
      }
    }
  }, [props.stuckEmails.emails]);

  useEffect(() => {
    if (selectedEmails.length !== 0) {
      setIsUploading(true);
      seLoaderText("Processing Selected Emails");
      props.processStuckEmails({
        groupId: selectedGroup,
        agentId: selectedAgent,
        emails: selectedEmails,
        onSuccess: () => {
          setIsUploading(false);
          seLoaderText("");
          setSelectedEmails([]);
          setSelection([]);
          getEmails(agentName);
        },
        onFailure: () => {
          setIsUploading(false);
          seLoaderText("");
          setSelectedEmails([]);
          setSelection([]);
        },
      });
    }
  }, [selectedEmails]);
  const getEmails = (agent) => {
    setIsUploading(true);
    seLoaderText(`Fetching Emails For Agent - ${agent}`);

    props.getEmails({
      groupName: groupName,
      agentName: agent,
      onSuccess: () => {
        setIsUploading(false);
        seLoaderText("");
      },
      onFailure: () => {
        setIsUploading(false);
        seLoaderText("");
      },
    });
  };

  const SelectRow = (selection) => {
    if (selection.length <= 5) {
      let selected = [];

      selected = [...selection];
      setSelection([...selected]);
      setShowPopup(false);

    } else {
      setSelection([...selectionRows]);
      setShowPopup(true);
    }
  };

  const closeModal = () => {
    setShowPopup(false);
  };
  return (
    <>
      {/* <Modal open={showPopup} onClose={closeModal} size="small">
          <Modal.Header>Alert</Modal.Header>
          <Modal.Content>Maximum Five Emails can be selected</Modal.Content>
          <Modal.Actions>
            <Button onClick={closeModal} negative>
              No
            </Button>
          </Modal.Actions>
        </Modal> */}
    <div id="content">
      <Dimmer active={isUploading}>
        <Loader size="large" active inline>
          {LoaderText}
        </Loader>
      </Dimmer>
      <Segment
        onClick={() => {
          props.onClose();
          props.clearEmails();
        }}
        style={{
          color: "#808080",
          float: "right",
          cursor: "pointer",
          marginTop: 0,
          position: "absolute",
          right: 19,
        }}
      >
        <Icon name="arrow left" className="left large" color="brown" />
      </Segment>
      <div style={{ margin: 15 }}>
        <Label
          color="green"
          tag
          pointing="left"
          size="large"
          style={{ cursor: "pointer" }}
        >
          Process Stuck Emails
        </Label>
      </div>
    
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "80vh",
        }}
      >
     
        <Grid style={{ width: "95%", marginLeft: 5 }}>
          <Grid.Row columns={3}>
            <Grid.Column width={4}>
              <Dropdown
                selection
                options={groups}
                style={{ width: "100%" }}
                defaultValue={selectedGroup}
                placeholder="Skill groups"
                onChange={(e, data) => {
                  setSelectedGroup(data.value);
                  setIsUploading(true);
                  setAgentName('')
                  seLoaderText("Fetching Agents");
                  props.getAgents({
                    groupId: data.value,
                    onSuccess: () => {
                      setIsUploading(false);
                      seLoaderText("");
                    },
                    onFailure: () => {
                      setIsUploading(false);
                      seLoaderText("");
                    },
                  });
                  groups.map((val) => {
                    if (data.value === val.key) {
                      setGroupName(val.text);
                    }
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                selection
                options={Agent}
                style={{ width: "100%" }}
                defaultValue={selectedAgent}
                placeholder="agents"
                onChange={(e, data) => {
                  setSelectedAgent(data.value);
                  setIsUploading(true);
                  Agent.map((val) => {
                    if (data.value === val.key) {
                      seLoaderText(`Fetching Emails For Agent - ${val.text}`);
                      setAgentName(val.text);
                      getEmails(val.text);
                    }
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                disabled={selectedAgent !== "" && selectionRows.length === 0}
                onClick={() => {
                  let emails = [...selectedEmails];
                  selectionRows.forEach((data) => {
                    rows.forEach((row) => {
                      if (data === row.id) {
                        emails.push(row);
                      }
                    });
                  });
                  setSelectedEmails([...emails]);
                }}
                color={selectionRows.length === 0 ? "grey" : "teal"}
              >
                Process
              </Button>
              <Button
                disabled={agentName === ""}
                color="green"
                onClick={() => {
                  getEmails(agentName);
                }}
              >
                Load Emails
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {showPopup === true && 
        <Segment style={{width:"95%"}}>
          <div style={{display:"flex",flexDirection:"row",flex:1,justifyContent:"space-between",margin:2}}>
          <Label color="red"> Maximum Five Emails can Be Selected</Label>
          <Icon  name="close" onClick={closeModal}/>


          </div>

        </Segment>

        }
        {listOfEmails.length !== 0 && (
          <div style={{ width: "95%", flex: 1, marginTop: 8 }}>
            <Gridded rows={rows} columns={columns}>
              <SelectionState
                selection={selectionRows}
                onSelectionChange={(selection) => {
                  SelectRow(selection);
                }}
              />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />

              <IntegratedPaging />
              <SearchState defaultValue="" />
              <IntegratedFiltering />
              <Table />
              <TableHeaderRow />
              <Toolbar />
              <SearchPanel />
              <TableSelection />
              <PagingPanel pageSizes={pageSizes} />
            </Gridded>
          </div>
        )}
      </div>
    </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    services: state.services,
    stuckEmails: state.stuckEmails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getAgents, getEmails, processStuckEmails, clearEmails },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProcessStuckEmails)
);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Segment, Input, Icon, Button , Dimmer , Loader} from 'semantic-ui-react';
import { Grid, GridColumn, Message, GridRow } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { isLoggedIn } from '../util';
import { forgotPasswordAction } from '../action/login';


class Forgot extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            email : '',
            showSuccessMsg : false,
            showFailureMsg : false,
            successMsg : '',
            failureMsg : '',
            isLoadingFlag : false
        }
    }


    componentWillMount() {
        if (isLoggedIn(this.props.auth)) {
            this.props.history.push(`/home`);
        }
    }

    componentWillReceiveProps(nextProps){
        if (isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/home`);
        }
        this.setState({isLoadingFlag : false })
        if(nextProps.forgotpassword.isForgotPassword){
            this.setState({
                email : '',
                showFailureMsg : false,
                showSuccessMsg : true,
                successMsg : "Reset link sent to your email."
            })
        }else if(nextProps.forgotpassword.forgotPasswordErrorMessage && !nextProps.forgotpassword.isForgotPassword){
            this.setState({
                showFailureMsg : true,
                showSuccessMsg : false,
                failureMsg : nextProps.forgotpassword.forgotPasswordErrorMessage
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/home`);
            return false;
        }
        return true;
    }

    login = () =>{
        this.props.history.push(`/login`);
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.forgotPassword();
        }
    }

    forgotPassword = () =>{
        this.setState({isLoadingFlag : true })
        this.props.forgotPasswordAction({email : this.state.email })
    }

    render() {
        var loginBoxStyle = {
            borderRadius: 6,
            WebkitBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            MozBoxShadow: '10px 11px 81px 3px rgba(191,191,191,1)',
            boxShadow: '10px 11px 81px 3px rgba(191,191,191,1)'
        }
        return (
            <div style={{marginTop : '20%'}}>
            {this.state.isLoadingFlag && 
                <Dimmer active>
                    <Loader size="large">Loading</Loader>
                </Dimmer>
            }
            <Grid columns='equal' verticalAlign='middle' style={{height: '100%'}} centered>
                <GridRow className="vcenter">
                    <Grid.Column></Grid.Column>
                    <GridColumn width={10}>
                        <Segment raised style={loginBoxStyle}>
                            <Grid columns={2}>
                                <GridRow columns='equal' divided>
                                    <GridColumn>
                                        {/* <img src='../images/textLogo.png' /> */}
                                    </GridColumn>
                                    <GridColumn verticalAlign="middle" style={{ paddingLeft: 50 }}>
                                        {this.state.showFailureMsg &&
                                            <Message warning>
                                                <Message.Header>{this.state.failureMsg}</Message.Header>
                                            </Message>
                                        }
                                        {this.state.showSuccessMsg &&
                                            <Message success>
                                                <Message.Header>{this.state.successMsg}</Message.Header>
                                            </Message>
                                        }
                                        <Input iconPosition='left' value={this.state.email} placeholder='Your Email Address' onChange={this.handleEmailChange}>
                                            <Icon name='at' />
                                            <input />
                                        </Input><br /><br />
                                        <div style={{display:'flex' , flexDirection:'row'}}>
                                            <Button primary onClick={this.forgotPassword}>Submit</Button>
                                            <div style={{marginLeft : 'auto' , marginTop : 10 , cursor:'pointer' }} onClick={this.login}>Login</div>
                                        </div>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </Segment>
                    </GridColumn>
                    <GridColumn></GridColumn>
                </GridRow>
            </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        forgotpassword: state.forgotpassword
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ forgotPasswordAction }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));

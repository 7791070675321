import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader,Select,Segment } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import passwordValidator from 'password-validator';

import { Scrollbars } from 'rc-scrollbars';

import { GetAdminUser , EditAdminUserAction } from './../action/addUser';

import { isLoggedIn , setupAxios ,firstLetterUpperCase } from '../util';

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'name', title: 'Name' },
        { name: 'email', title: 'Email' },
        { name: 'mobileNumber', title: 'Mobile No' },
        { name: 'role', title: 'Role' },
        { name: 'edit', title: 'Edit'}
      ],
      columnWidths : [
        { columnName: 'name', width: 200  },
        { columnName: 'email', width: 400 },
        { columnName: 'mobileNumber', width: 200  },
        { columnName: 'role', width: 200  },
        { columnName: 'edit', width: 100 }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showAdminEditModal : false,
      adminName : '',
      adminEmail : '',
      adminPassword : '',
      adminId : '',
      adminMobile : '',
      adminRole : '',
      adminEmailError : false,
      adminNameError : false,
      adminMobileError : false,
      adminPasswordError : false,
      adminRoleError : false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAdminUser();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });
    console.log(nextProps)
    if(nextProps.addUser.data && nextProps.addUser.data.length){
      var rows = nextProps.addUser.data.map((d) => {
        d.role =  firstLetterUpperCase(d.role);
        d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editAdmin(d)}><Icon name={'edit'}></Icon></Button>;
        return(d)
      });
      this.setState({ rows });
    }

    if(nextProps.addUser.isAdminUserEdit){
      this.setState({ successMessage : "Admin User Updated Successfully." , successModal : true });
    }else if(nextProps.addUser.adminUserEditErrorMessage){
      this.setState({ errorMessage : nextProps.addUser.adminUserEditErrorMessage, errorModal : true });
    }

  }

  editAdmin = (admin) =>{
    this.setState({  adminId : admin.id , adminEmail : admin.email , adminName : admin.name , adminPassword : admin.password ,adminRole : admin.role ,adminMobile : admin.mobileNumber, showAdminEditModal : true});
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  handleEmailChange = (email) =>{
    this.setState({ adminEmail : email.target.value , adminEmailError : false });
  }

  handleMobileChange = (mobileNumber) =>{
    this.setState({ adminMobile : mobileNumber.target.value , adminMobileError : false });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  handlePasswordChange = (password) =>{

    var schema = new passwordValidator();
    schema
    .is().min(8)
    .is().max(24)
    .has().uppercase()
    .has().lowercase()        
    .has().digits(1) 
    .has().not().spaces()
    .has().symbols()  
    
    if( password.target.value.length <= 24 ){

      if(schema.validate(password.target.value)){

        this.setState({ adminPassword : password.target.value , adminPasswordError : false });
  
      }else{
  
        this.setState({ adminPassword : password.target.value , adminPasswordError : true });
  
      }

    }

  }

  handleNameChange = (name) =>{
    this.setState({ adminName : name.target.value , adminNameError : false });
  }

  handleRoleChange = (e,data) =>{
       console.log(data.value, data.value.toLowerCase())
	  this.setState({ adminRole : data.value , adminRoleError : false });
  }

  updateAdmin = () =>{

    var adminEmail = this.state.adminEmail;
    var adminName = this.state.adminName;
    var adminMobile = this.state.adminMobile;
    // var adminPassword = this.state.adminPassword;
    var adminRole = this.state.adminRole;

    // var schema = new passwordValidator();
    // schema
    // .is().min(8)
    // .is().max(24)
    // .has().uppercase()
    // .has().lowercase()        
    // .has().digits(1) 
    // .has().not().spaces()
    // .has().symbols()  

    // var checkPassword = schema.validate(adminPassword);

    if(adminEmail && adminName && adminMobile){
      this.setState({isUploading : true });
      this.props.EditAdminUserAction({ email : adminEmail , name : adminName , mobileNumber : adminMobile , role: this.state.adminRole, adminId : this.state.adminId });
    }else{

      if(!adminEmail){
        this.setState({adminEmailError : true });
      }
      if(!adminName){
        this.setState({adminNameError : true });
      }
      if(!adminMobile ){
        this.setState({adminMobileError : true });
      }
      if(!adminRole ){
        this.setState({adminRoleError : true });
      }
    }

  }

  adminEditCloseModal = () =>{
    this.setState({ showAdminEditModal : false });
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showAdminEditModal : false  });
    this.props.GetAdminUser();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  render() {

    var roleArray = [
      {
        key : 1,
        text : 'User',
        value : 'User'
      },
      {
        key : 2,
        text : 'Admin',
        value : 'Admin'
      }
    ]

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20 }}>
          <Label
              color="green"
              onClick={this.props.openAddUserPage}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add User
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            // defaultSorting={[
            //   { columnName: "name", direction: "desc" },
            //   { columnName: "email", direction: "desc" },
            //   { columnName: "group", direction: "desc" },
            //   { columnName: "status", direction: "desc" },
            //   { columnName: "break", direction: "desc" },
            //   { columnName: "activestatus", direction: "asc" },
            // ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
 
       <MDBModal isOpen={this.state.showAdminEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Admin
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Name : </b></label>
                <Input onChange={this.handleNameChange} value={this.state.adminName} placeholder='Enter the Name' style={this.state.adminNameError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b>Email  :</b></label>
                <Input onChange={this.handleEmailChange} value={this.state.adminEmail}   placeholder='Enter the Email' style={this.state.adminEmailError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                {/* <label><b>Password  :</b></label>
                <Input onChange={this.handlePasswordChange} value={this.state.adminPassword}   placeholder='Enter the Password' style={this.state.adminPasswordError?{border: '1px solid red'}:{}}></Input>
                <br/>
                <label style={this.state.adminPasswordError ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                <br/><br/>  */}
                
                <label><b>Mobile Number :</b></label>
                <Input onChange={this.handleMobileChange} value={this.state.adminMobile}   placeholder='Enter the Mobile Number' style={this.state.adminMobileError?{border: '1px solid red'}:{}}></Input>
                <br/><br/> 

                <label><b>Role  :</b></label>
                <Select value={this.state.adminRole} options={roleArray} onChange={this.handleRoleChange} placeholder='Select Role' ></Select> 
                
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.adminEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateAdmin}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      addUser: state.addUser
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAdminUser,
    EditAdminUserAction
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agent));
import {
    GET_ALL_GENERALISSUE_CONFIG_SUCCESS,
    GET_ALL_GENERALISSUE_CONFIG_FAILURE,
    SAVE_GENERALISSUE_CONFIG_SUCCESS,
    SAVE_GENERALISSUE_CONFIG_FAILURE,
    EDIT_GENERALISSUE_CONFIG_SUCCESS,
    EDIT_GENERALISSUE_CONFIG_FAILURE,
    DELETE_GENERALISSUE_CONFIG_SUCCESS,
    DELETE_GENERALISSUE_CONFIG_FAILURE,
    ACTIVE_GENERALISSUE_SUCCESS,
    ACTIVE_GENERALISSUE_FAILURE,
    DEACTIVE_GENERALISSUE_SUCCESS,
    DEACTIVE_GENERALISSUE_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state , action ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_GENERALISSUE_CONFIG_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_GENERALISSUE_CONFIG_FAILURE:
            state = {
                isGeneralIssueGetError : true,
                generalIssueGetErrorMessage : action.payload.message
            }
            return state;
        case SAVE_GENERALISSUE_CONFIG_SUCCESS:
            state = {
                isGeneralIssueSave : true
            };
            return state;

        case SAVE_GENERALISSUE_CONFIG_FAILURE:
            state = {
                isGeneralIssueSave : false,
                generalIssueSaveErrorMessage : action.payload.message
            }
            return state;
        case DELETE_GENERALISSUE_CONFIG_SUCCESS:
            state = {
                isGeneralIssueDelete : true
            };
            return state;

        case DELETE_GENERALISSUE_CONFIG_FAILURE:
            state = {
                isGeneralIssueDelete : false,
                generalIssueDeleteErrorMessage : action.payload.message
            }
            return state;

        case EDIT_GENERALISSUE_CONFIG_SUCCESS:
            state = {
                isGeneralIssueEdit : true
            };
            return state;

        case EDIT_GENERALISSUE_CONFIG_FAILURE:
            state = {
                isGeneralIssueEdit : false,
                generalIssueEditErrorMessage : action.payload.message
            }
            return state;
        case ACTIVE_GENERALISSUE_SUCCESS:
            state = {
                isGeneralIssueActive : true
            };
            return state;

        case ACTIVE_GENERALISSUE_FAILURE:
            state = {
                isGeneralIssueActive : false,
                generalIssueActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_GENERALISSUE_SUCCESS:
            state = {
                isGeneralIssueDeactive : true
            };
            return state;

        case DEACTIVE_GENERALISSUE_FAILURE:
            state = {
                isGeneralIssueDeactive : false,
                generalIssueDeactiveErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader ,Segment} from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { Scrollbars } from 'rc-scrollbars';

import { GetAllGeneralIssueConfig , SaveGeneralIssue , EditGeneralIssue , DeleteGeneralIssue , Active , Deactive } from './../action/generalIssueConfig';
import { isLoggedIn , setupAxios } from '../util';

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'generalIssueReason', title: 'General Issue Reason' },
        { name: 'edit', title: 'Edit'},
        { name: 'status', title: 'Status'},
      ],
      columnWidths : [
        { columnName: 'generalIssueReason', width: 600  },
        { columnName: 'edit', width: 150  },
        { columnName: 'status', width: 150  }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showGeneralIssueAddModal : false,
      generalIssueReason : '',
      generalIssueReasonError : false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      showGeneralIssueEditModal : false,
      generalIssueId : '',
      isActive : true,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllGeneralIssueConfig();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });

    if(nextProps.generalIssue.data && nextProps.generalIssue.data.length){

      var rows = nextProps.generalIssue.data.map((d) => {

          d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editGeneralIssue(d)}><Icon name={'edit'}></Icon></Button>
          d.status = d.isActive ? 'Active': 'Deactive';
          return(d)
    
      });

      this.setState({ rows });
    }else{
      this.setState({ rows : [] });
    }

    if(nextProps.generalIssue.isGeneralIssueSave){
      this.setState({ successMessage : "GeneralIssue Added Successfully." , successModal : true });
    }else if(nextProps.generalIssue.generalIssueSaveErrorMessage){
      this.setState({ errorMessage : nextProps.generalIssue.generalIssueSaveErrorMessage, errorModal : true });
    }
    
    if(nextProps.generalIssue.isGeneralIssueDelete){
      this.setState({ successMessage : "GeneralIssue Deleted Successfully." , successModal : true });
    }else if(nextProps.generalIssue.generalIssueDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.generalIssue.generalIssueDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.generalIssue.isGeneralIssueEdit){
      this.setState({ successMessage : "GeneralIssue Updated Successfully." , successModal : true });
    }else if(nextProps.generalIssue.generalIssueEditErrorMessage){
      this.setState({ errorMessage : nextProps.generalIssue.generalIssueEditErrorMessage, errorModal : true });
    }

    if(nextProps.generalIssue.isGeneralIssueActive){
      this.setState({ successMessage : "General Issue Active Successfully." , successModal : true });
    }else if(nextProps.generalIssue.generalIssueActiveErrorMessage){
      this.setState({ errorMessage : nextProps.generalIssue.generalIssueActiveErrorMessage, errorModal : true });
    }

    if(nextProps.generalIssue.isGeneralIssueDeactive){
      this.setState({ successMessage : "General Issue Deactive Successfully." , successModal : true });
    }else if(nextProps.generalIssue.generalIssueDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.generalIssue.generalIssueDeactiveErrorMessage, errorModal : true });
    }

  }

  deleteGeneralIssue = (generalIssueReason) =>{
    this.setState({isUploading : true });
    this.props.DeleteGeneralIssue( generalIssueReason.id );
  }

  editGeneralIssue = (generalIssueReason) =>{
    this.setState({ generalIssueReason : generalIssueReason.generalIssueReason, showGeneralIssueEditModal : true ,generalIssueId : generalIssueReason.id  , isActive : generalIssueReason.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  addGeneralIssueModal = () =>{
    this.setState({ showGeneralIssueAddModal : true });
  }

  handleGeneralIssueReasonChange = (generalIssueReason) =>{
    this.setState({ generalIssueReason : generalIssueReason.target.value , generalIssueReasonError : false });
  }


  saveGeneralIssue = () =>{

    var generalIssueReason = this.state.generalIssueReason;

    if(generalIssueReason){
      this.setState({isUploading : true });
      this.props.SaveGeneralIssue({ generalIssueReason : generalIssueReason });
    }else{

      this.setState({generalIssueReasonError : true });
    
    }

  }

  updateGeneralIssue = () =>{

    var generalIssueReason = this.state.generalIssueReason;

    if(generalIssueReason){
      this.setState({isUploading : true });
      this.props.EditGeneralIssue({ generalIssueReason : generalIssueReason , generalIssueConfigId : this.state.generalIssueId });
    }else{

      this.setState({generalIssueReasonError : true });
      
    }

  }


  closeGeneralIssueCloseModal = () =>{
    this.setState({ showGeneralIssueAddModal : false });
  }

  generalIssueEditCloseModal = () =>{
    this.setState({ showGeneralIssueEditModal : false , generalIssueReason : ''});
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showGeneralIssueAddModal : false , showGeneralIssueEditModal : false });
    this.props.GetAllGeneralIssueConfig();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  active = (generalIssueId) =>{
    this.setState({isUploading : true });
    this.props.Active( generalIssueId );
  }

  deactive = (generalIssueId) =>{

    this.setState({isUploading : true });
    this.props.Deactive( generalIssueId );
  }

  render() {

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addGeneralIssueModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add General Issue Config
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            defaultSorting={[
              { columnName: "generalIssueType", direction: "desc" },
            ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
        <MDBModal isOpen={this.state.showGeneralIssueAddModal} centered size="lg">
            <MDBModalHeader>
                Add General Issue Config
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> General Issue Reason : </b></label>
                <Input onChange={this.handleGeneralIssueReasonChange}  placeholder='Enter the General Issue Reason' style={this.state.generalIssueReasonError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeGeneralIssueCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveGeneralIssue}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showGeneralIssueEditModal} centered size="lg">
            <MDBModalHeader>
                Edit General Issue Config
            </MDBModalHeader> 
            <MDBModalBody>

                <label><b> General Issue Reason : </b></label>
                <Input onChange={this.handleGeneralIssueReasonChange} value={this.state.generalIssueReason} placeholder='Enter the General Issue Reason' style={this.state.generalIssueReasonError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/> 

            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.generalIssueEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateGeneralIssue}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactive(this.state.generalIssueId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.active(this.state.generalIssueId) }>Active</Button>
              }
            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      generalIssue: state.generalIssue
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAllGeneralIssueConfig,
    SaveGeneralIssue,
    EditGeneralIssue,
    DeleteGeneralIssue,
    Active,
    Deactive
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agent));
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader,Segment } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";
import TextField from '@material-ui/core/TextField';

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { Scrollbars } from 'rc-scrollbars';

import { GetAllShiftConfig , SaveShift , EditShift , DeleteShift , Active , Deactive } from './../action/shiftConfig';
import { isLoggedIn , setupAxios } from '../util';

class Shift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'shiftType', title: 'Shift Type' },
        { name: 'shiftStartTime', title: 'Shift Start Time' },
        { name: 'shiftEndTime', title: 'Shift End Time' },
        { name: 'edit', title: 'Edit'},
        { name: 'status', title: 'Status'},
      ],
      columnWidths : [
        { columnName: 'shiftType', width: 400  },
        { columnName: 'shiftStartTime', width: 200 },
        { columnName: 'shiftEndTime', width: 200  },
        { columnName: 'edit', width: 100  },
        { columnName: 'status', width: 100  },
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showShiftAddModal : false,
      shiftType : '',
      shiftStartTime : '',
      shiftEndTime : '',
      isActive : true,
      shiftTypeError : false,
      shiftStartTimeError : false,
      shiftEndTimeError : false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      showShiftEditModal : false,
      shiftId : '',
      time: new Date(),
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllShiftConfig();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });

    if(nextProps.shift.data && nextProps.shift.data.length){

      var rows = nextProps.shift.data.map((d) => {

          d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editShift(d)}><Icon name={'edit'}></Icon></Button>
          d.status = d.isActive ? 'Active' : 'Deactive';
          return(d)
    
      });

      this.setState({ rows });
    }else{
      this.setState({ rows : [] });
    }

    if(nextProps.shift.isShiftSave){
      this.setState({ successMessage : "Shift Added Successfully." , successModal : true });
    }else if(nextProps.shift.shiftSaveErrorMessage){
      this.setState({ errorMessage : nextProps.shift.shiftSaveErrorMessage, errorModal : true });
    }
    
    if(nextProps.shift.isShiftDelete){
      this.setState({ successMessage : "Shift Deleted Successfully." , successModal : true });
    }else if(nextProps.shift.shiftDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.shift.shiftDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.shift.isShiftEdit){
      this.setState({ successMessage : "Shift Updated Successfully." , successModal : true });
    }else if(nextProps.shift.shiftEditErrorMessage){
      this.setState({ errorMessage : nextProps.shift.shiftEditErrorMessage, errorModal : true });
    }

    if(nextProps.shift.isShiftActive){
      this.setState({ successMessage : "Shift Active Successfully." , successModal : true });
    }else if(nextProps.shift.shiftActiveErrorMessage){
      this.setState({ errorMessage : nextProps.shift.shiftActiveErrorMessage, errorModal : true });
    }

    if(nextProps.shift.isShiftDeactive){
      this.setState({ successMessage : "Shift Deactive Successfully." , successModal : true });
    }else if(nextProps.shift.shiftDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.shift.shiftDeactiveErrorMessage, errorModal : true });
    }

  }

  deleteShift = (shift) =>{
    this.setState({isUploading : true });
    this.props.DeleteShift( shift.id );
  }

  editShift = (shift) =>{
    this.setState({ shiftType : shift.shiftType , shiftStartTime : shift.shiftStartTime ,shiftEndTime : shift.shiftEndTime , showShiftEditModal : true ,shiftId : shift.id , isActive : shift.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  addShiftModal = () =>{
    this.setState({ showShiftAddModal : true });
  }

  handleShiftTypeChange = (shiftType) =>{
    this.setState({ shiftType : shiftType.target.value , shiftTypeError : false });
  }

  handleShiftStartTimeChange = (shiftStartTime) =>{
    this.setState({ shiftStartTime : shiftStartTime.target.value , shiftStartTimeError : false });
  }

  handleShiftEndTimeChange = (shiftEndTime) =>{
    this.setState({ shiftEndTime : shiftEndTime.target.value , shiftEndTimeError : false });
  }

  active = (shiftId) =>{
    this.setState({isUploading : true });
    this.props.Active( shiftId );
  }

  deactive = (shiftId) =>{

    this.setState({isUploading : true });
    this.props.Deactive( shiftId );
  }

  saveShift = () =>{

    var shiftType = this.state.shiftType;
    var shiftStartTime = this.state.shiftStartTime;
    var shiftEndTime = this.state.shiftEndTime;

    if(shiftType && shiftStartTime && shiftEndTime ){
      this.setState({isUploading : true });
      this.props.SaveShift({ shiftType : shiftType , shiftStartTime : shiftStartTime , shiftEndTime : shiftEndTime });
    }else{

      if(!shiftType){
        this.setState({shiftTypeError : true });
      }
      if(!shiftStartTime){
        this.setState({shiftStartTimeError : true });
      }
      if(!shiftEndTime ){
        this.setState({shiftEndTimeError : true });
      }
    }

  }

  updateShift = () =>{

    var shiftType = this.state.shiftType;
    var shiftStartTime = this.state.shiftStartTime;
    var shiftEndTime = this.state.shiftEndTime;

    if(shiftType && shiftStartTime && shiftEndTime ){
      this.setState({isUploading : true });
      this.props.EditShift({ shiftType : shiftType , shiftStartTime : shiftStartTime , shiftEndTime : shiftEndTime , shiftConfigId : this.state.shiftId });
    }else{

      if(!shiftType){
        this.setState({shiftTypeError : true });
      }
      if(!shiftStartTime){
        this.setState({shiftStartTimeError : true });
      }
      if(!shiftEndTime ){
        this.setState({shiftEndTimeError : true });
      }
    }

  }


  closeShiftCloseModal = () =>{
    this.setState({ showShiftAddModal : false });
  }

  shiftEditCloseModal = () =>{
    this.setState({ showShiftEditModal : false , shiftType : '' , shiftStartTime : '' , shiftEndTime : ''});
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showShiftAddModal : false , showShiftEditModal : false });
    this.props.GetAllShiftConfig();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  render() {

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addShiftModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add Shift Config
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            defaultSorting={[
              { columnName: "shiftType", direction: "desc" },
            ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>

        <MDBModal isOpen={this.state.showShiftAddModal} centered size="lg">
            <MDBModalHeader>
                Add Shift Config
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Shift Type : </b></label>
                <Input onChange={this.handleShiftTypeChange}  placeholder='Enter the Shift Type' style={this.state.shiftTypeError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b> Shift Start Time :</b></label>
                {/* <Input onChange={this.handleShiftStartTimeChange}  placeholder='Enter the Shift Start Time' style={this.state.shiftStartTimeError?{border: '1px solid red'}:{}}></Input> */}
                <TextField
                  type="time"
                  value={this.state.shiftStartTime}
                  style={this.state.shiftStartTimeError ? {border: '1px solid red' , width : '100%', padding : 5 }:{width : '100%',border: '1px solid black' , padding : 5 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={this.handleShiftStartTimeChange}
                />
                <br/><br/> 
                
                <label><b>  Shift End Time  :</b></label>
                {/* <Input onChange={this.handleShiftEndTimeChange}  placeholder='Enter the Shift End Time' style={this.state.shiftEndTimeError?{border: '1px solid red'}:{}}></Input> */}

                <TextField
                  type="time"
                  value={this.state.shiftEndTime}
                  style={this.state.shiftEndTimeError ? {border: '1px solid red' , width : '100%', padding : 5 }:{width : '100%',border: '1px solid black' , padding : 5 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={this.handleShiftEndTimeChange}
                />
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeShiftCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveShift}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showShiftEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Shift Config
            </MDBModalHeader>
            <MDBModalBody>

            <label><b> Shift Type : </b></label>
                <Input onChange={this.handleShiftTypeChange} value={this.state.shiftType} placeholder='Enter the Shift Type' style={this.state.shiftTypeError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
                <label><b> Shift Start Time :</b></label>
                {/* <Input onChange={this.handleShiftStartTimeChange} value={this.state.shiftStartTime}  placeholder='Enter the Shift Start Time' style={this.state.shiftStartTimeError?{border: '1px solid red'}:{}}></Input> */}
                <TextField
                  type="time"
                  value={this.state.shiftStartTime}
                  style={this.state.shiftStartTimeError ? {border: '1px solid red' , width : '100%', padding : 5 }:{width : '100%',border: '1px solid black' , padding : 5 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={this.handleShiftStartTimeChange}
                />
                <br/><br/> 
                
                <label><b>  Shift End Time  :</b></label>
                {/* <Input onChange={this.handleShiftEndTimeChange} value={this.state.shiftEndTime} placeholder='Enter the Shift End Time' style={this.state.shiftEndTimeError?{border: '1px solid red'}:{}}></Input> */}
                <TextField
                  type="time"
                  value={this.state.shiftEndTime}
                  style={this.state.shiftEndTimeError ? {border: '1px solid red' , width : '100%', padding : 5 }:{width : '100%',border: '1px solid black' , padding : 5 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  onChange={this.handleShiftEndTimeChange}
                />

            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.shiftEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateShift}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactive(this.state.shiftId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.active(this.state.shiftId) }>Active</Button>
              }
            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      shift: state.shift
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAllShiftConfig,
    SaveShift,
    EditShift,
    DeleteShift,
    Active,
    Deactive
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shift));
import {
    LOGIN_FAILURE_ACTION,
    LOGIN_SUCCESS_ACTION,
    LOGOUT_ACTION
} from '../action/type';
import moment from 'moment';

import { setupAxios  } from './../util';

const INIT_STATE = {};

export default function ( state  , action  ) {
    if (typeof state === 'undefined') {
        state = INIT_STATE
    }
    switch (action.type) {
        case LOGIN_SUCCESS_ACTION:
            state = {
                id : action.payload.id,
                email: action.payload.email,
                displayName: action.payload.name,
                mobileNo: action.payload.mobileNo,
                role: action.payload.role || '',
                accessToken : action.payload.token,
                expiresIn: moment().add(action.payload.expires_in, 'seconds').format(),
                loginError: false,
                orgs: action.payload.orgs || []
            }
            console.log('login success');
            setupAxios(action.payload.token);
            return state;

        case LOGIN_FAILURE_ACTION:
            state = {
                loginError: true
            }
            console.log('login failed');
            return state;

        case LOGOUT_ACTION:
            state = {}
            return state;
        default :
            return state;
    }
}
import React,{Component} from 'react';
import { Button ,Input , Icon,Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../util';
import { changePasswordAction , logoutAction } from '../action/login';
import {  MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import passwordValidator from 'password-validator';


 class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword : '',
            validOldPassword : false,
            newPassword : '',
            validNewPassword : false,
            confirmPassword : '',
            validConfirmPassword : false,
            showChangePasswordConfirmModal : false,
            showSuccesModal : false,
            showFailureModal : false,
            successMsg : '',
            failureMsg : '',
            passwordNotMached : false,
            width: window.innerWidth,
            height: window.innerHeight,
            isUploading:false
        }
    }

    componentWillReceiveProps(nextProps) {

        if (!isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/login`);
        }
        // this.setState({isUploading : false });

        if(nextProps.changePassword.passwordChanged){
            this.setState({
                showChangePasswordConfirmModal : false ,
                showSuccesModal : true,
                successMsg : "Password Changed Successfully."
            })
        }else if(nextProps.changePassword.changepasswordErrorMessage){
            this.setState({
                showChangePasswordConfirmModal : false ,
                showFailureModal : true,
                failureMsg : nextProps.changePassword.changepasswordErrorMessage
            })
        }
        this.setState({isLoadingFlag : false});
    }

    componentWillMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
        // this.setState({isUploading : true });
    }

    shouldComponentUpdate(nextProps, nextState) { 
        if (!isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    oldPasswordOnChange = (e) =>{
        this.setState({
            oldPassword : e.target.value,
            validOldPassword : false
        })
    }

    newPasswordOnChange = (e) =>{

        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  

        if( e.target.value.length <= 24 ){

            var checkPassword = schema.validate(e.target.value);

            if(checkPassword){

                this.setState({
                    newPassword : e.target.value,
                    validNewPassword : false
                }) 
    
            }else{
    
                this.setState({
                    newPassword : e.target.value,
                    validNewPassword : true
                }) 
    
            }

        }
     
    }

    confirmPasswordOnChange = (e) =>{

        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  
    
        if( e.target.value.length <= 24 ){

            var checkPassword = schema.validate(e.target.value);
        
            if(checkPassword){
    
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : false,
                    passwordNotMached : false
                }) 
    
            }else{
    
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : true
                }); 
    
            }
            
        }

    }

    changePasswordConfirm = () =>{

        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  

        var checkNewPassword = schema.validate(this.state.newPassword);
        var checkConfirmPassword = schema.validate(this.state.confirmPassword);

        if(this.state.oldPassword  && checkNewPassword && checkConfirmPassword && this.state.newPassword === this.state.confirmPassword){

            this.setState({
                showChangePasswordConfirmModal : true
            });

        }else{

            if(!this.state.oldPassword){
                this.setState({validOldPassword : true});
            }else if(!checkNewPassword){
                this.setState({validNewPassword : true});
            }else if(!checkConfirmPassword){
                this.setState({validConfirmPassword : true});
            }else if(this.state.newPassword !== this.state.confirmPassword ){
                this.setState({passwordNotMached : true});
            }

        }
    }

    closeChangePasswordConfirmModal = () =>{
        this.setState({
            showChangePasswordConfirmModal : false
        })  
    }

    changePassword = () =>{
        // this.setState({isUploading : false });

        this.props.changePasswordAction({ userId : this.props.auth.id , email: this.props.auth.email,  role:this.props.auth.role,  oldPassword : this.state.oldPassword , newPassword : this.state.newPassword , confirmPassword : this.state.confirmPassword });       
    }

    closeSuccessModal = () =>{
        this.setState({
            showChangePasswordConfirmModal : false,
            showSuccesModal : false,
        }) 
        // this.setState({isUploading : true });

        this.props.logoutAction();
    }

    closeFailureModal = () =>{
        this.setState({
            showFailureModal : false,
            showChangePasswordConfirmModal : false
        }) 
    }

    handleBack = () => {
        if(this.props.auth.role === 'agent'){
            this.props.logoutAction();
            this.props.history.push('/home')
            
        }else{
            this.props.onClose();
        }
    }

    
    render(){
         console.log( this.props, "##########################")
         
        return( 
                
            <div id="content"> 
                <Segment onClick={this.handleBack} style={{
                        color: '#808080',           
                        float: 'right',
                        cursor: 'pointer',
                        marginTop: 0,
                        position: 'absolute',
                        right: 19
                    }}>
                <Icon name="arrow left" className="left large" color="brown"/> 
                </Segment>
                <div style={{margin:10}}>
                    <h2 >Change Password</h2>
                    <hr/>
                    <br/><br/>
                    <div style={{paddingLeft :30}}>
                        <label><b>User Name :</b></label>
                        <br/><br/>
                             <Input style={ {width:'50%'}} readOnly={true} value={this.props.auth.displayName}></Input>
                        <br/><br/>
                        <label><b>Old Password:</b></label>
                        <br/><br/>
                            <Input style={this.state.validOldPassword ? {border:'1px solid red' ,width:'50%'} : {width:'50%'}} onChange={this.oldPasswordOnChange} placeholder='Enter the old password'></Input>
                            <br/>
                            <label style={this.state.validOldPassword ? {color:'red'} : {display:'none'}}>Please Enter Old Password.</label>
                        <br/><br/>
                            <label><b>New Password :</b></label>
                        <br/><br/>
                            <Input type='password' style={this.state.validNewPassword ? {border:'1px solid red',width:'50%'} : {width:'50%'}} onChange={this.newPasswordOnChange} value={this.state.newPassword}  placeholder='Enter the new password'></Input>
                            <br/>
                            <label style={this.state.validNewPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                        <br/><br/>
                            <label><b>Confirm Password :</b></label>
                        <br/><br/>
                            <Input type='password'  style={this.state.validConfirmPassword || this.state.passwordNotMached ? {border:'1px solid red',width:'50%'} : {width:'50%'}} value={this.state.confirmPassword} onChange={this.confirmPasswordOnChange} placeholder='Enter the confirm password'></Input>
                            <br/>
                            <label style={this.state.validConfirmPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                            <label style={this.state.passwordNotMached ? {color:'red'} : {display:'none'}}>Password Not Matched.</label>
                        <br/><br/>
                            <Button  onClick={this.changePasswordConfirm} style={{backgroundColor:"#03a5fc" ,color:"white",padding:10}}>Submit</Button>
                    </div>
                </div>
                
                <MDBModal isOpen={this.state.showChangePasswordConfirmModal} centered size="lg"> 
                    <MDBModalHeader>Change Password</MDBModalHeader>
                    <MDBModalBody>
                        <p>Are you sure you want to change the password?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Button negative onClick={this.closeChangePasswordConfirmModal}>Cancel</Button>
                        <Button color='blue' content='Change' onClick={this.changePassword}/>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.showSuccesModal}
                    centered
                >
                    <MDBModalHeader>
                        <h3>{this.state.successMsg}</h3>
                    </MDBModalHeader>
                    <MDBModalFooter>
                        <Button color='blue' onClick={this.closeSuccessModal} inverted>
                            <Icon name='checkmark' /> Ok
                        </Button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.showFailureModal}
                    centered
                >
                    <MDBModalHeader>
                        <h3>{this.state.failureMsg}</h3>
                    </MDBModalHeader>
                    <MDBModalFooter>
                        <Button color='red' onClick={this.closeFailureModal} inverted>
                            <Icon name='checkmark' /> Ok
                        </Button>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        changePassword : state.changePassword
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            logoutAction,
            changePasswordAction
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangePassword)
);

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Segment , Menu, Dropdown , Grid , Icon } from 'semantic-ui-react';

import { isLoggedIn } from '../util';

import { logoutAction } from "../action/login";
import { getGroups } from '../action/ProcessStuck';
import Agent from './Agent.jsx';
import Group from './Group.jsx';
import BreakConfig from './BreakConfig.jsx';
import GeneralIssue from './GeneralIssue.jsx';
import ReportConfig from './ReportConfig.jsx';
import ShiftConfig from './ShiftConfig.jsx';
import ChangePassword from './ChangePassword.jsx';
import Services from './Services.jsx';
import AddUser from './AddUser.jsx';
import EditAdminUser from './EditAdminUser';
import ProcessStuckEmails from './ProcessStuckEmails';

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {  
        width: window.innerWidth,
        height: window.innerHeight,
        currentPage : 'home'
      };
    }
  
    componentWillMount() {
      if (!isLoggedIn(this.props.auth)) {
        this.props.history.push(`/login`);
      }
    }
  
    shouldComponentUpdate(nextProps, nextState) {
      if (!isLoggedIn(nextProps.auth)) {
        this.props.history.push(`/login`);
        return false;
      }
      return true;
    }
  
    logout = () => {
      this.props.logoutAction();
    };

    openPage = (page) =>{
      if(page === 'process-stuck'){
        this.props.getGroups()
      }
      this.setState({ currentPage : page });
    }

    openAddUserPage = () =>{
      this.setState({ currentPage : 'adduser' });
    }

    handleCloseClick = () =>{
      console.log( this.state.currentPage,  "$$$$$$$$$$$$$$$$$")
      this.setState({ currentPage : 'home' });
    }

    // changePassword = (page) =>{
    //   this.setState({ currentPage : page });
    // }
  
    render() {
      
      var items = [
        {
          title : "Agent",
          page : "agent",
          icon : 'user'
        },
        {
          title : "Group",
          page : "group",
          icon : 'users'
        },
        {
          title : "Shift Config",
          page : "shiftConfig",
          icon : 'strikethrough'
        },
        {
          title : "Break Config",
          page : "breakConfig",
          icon : 'stop circle outline'
        },
        {
          title : "General Issue Config",
          page : "generalIssueConfig",
          icon : 'american sign language interpreting'
        },
        {
          title : "Report Config",
          page : "reportConfig",
          icon : 'registered outline'
        },
        {
          title : "Services",
          page : "services",
          icon : 'servicestack'
        },
        {
          title : "Process Stuck Emails",
          page : "process-stuck",
          icon : 'mail outline'
        }
      ];

      return (
        
        <div style={{ height: "100%" }}>
        <Segment raised style={{ backgroundColor: "#fafafa", height: 60 }}>
          <div
            style={{
              display: "inline-block",
              float: "right",
              paddingRight: 50
            }}
          >
            <Menu.Menu
              style={{ display: "inline", float: "right", marginTop: 8 , marginRight : 20 }}
            >
              <Dropdown pointing text={this.props.auth.displayName}>
                <Dropdown.Menu>
                  {(this.props.auth.role === 'admin' || this.props.auth.role === 'Admin' )&& <Dropdown.Item onClick={() => this.openPage('adduser')}>Add User</Dropdown.Item>}
                  {(this.props.auth.role === 'admin' || this.props.auth.role === 'Admin' ) && <Dropdown.Item onClick={() => this.openPage('edituser')}>Edit User</Dropdown.Item>}
                  <Dropdown.Item onClick={() => this.openPage('changepassword')}>Change Password</Dropdown.Item>
                  <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </div>
        </Segment>

          {this.props.auth.role !== 'agent'  && this.state.currentPage === 'home' && 
            <Segment style={{height : this.state.height - 100 }}>
              <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' ,marginRight : '10px'}}>
                  <Grid divided='vertically'>
                    <Grid.Row columns={4}>
                        {items.map((item, index) => {
                            return (
                                <Grid.Column key={index}>
                                <div 
                                  className="slider-item" 
                                  style={{  paddingTop:30, backgroundColor:"#4495fe", color:"white" ,textAlign : 'center', padding : 20 ,cursor: "pointer" }}
                                  onClick={( )=> {this.openPage(item.page)}}
                                >
                                  <Icon name={item.icon} size='big'></Icon>
                                  <br/><br/>
                                  {item.title}                                 
                                </div>
                            </Grid.Column>
                            )
                        })}
                    </Grid.Row>
                  </Grid>
              </div>
            </Segment>
          }

          {this.state.currentPage === 'agent' && 
            <Agent 
              onClose={this.handleCloseClick} 
            />
          }

          {this.state.currentPage === 'group' && 
            <Group 
              onClose={this.handleCloseClick}
            />
          }

          {this.state.currentPage === 'shiftConfig' && 
            <ShiftConfig
              onClose={this.handleCloseClick}
            />
          }

          {this.state.currentPage === 'breakConfig' && 
            <BreakConfig
              onClose={this.handleCloseClick}
            />
          }

          {this.state.currentPage === 'generalIssueConfig' && 
            <GeneralIssue
              onClose={this.handleCloseClick}
            />
          }

          {this.state.currentPage === 'reportConfig' && 
            <ReportConfig
              onClose={this.handleCloseClick}
            />
          }

          {this.state.currentPage === 'changepassword' && 
            <ChangePassword
              onClose={this.handleCloseClick}
            />
          }
          {this.state.currentPage === 'services' && 
            <Services
              onClose={this.handleCloseClick}
            />
          }
          {this.state.currentPage === 'process-stuck' && 
            <ProcessStuckEmails
              onClose={this.handleCloseClick}
            />
          }
          {this.state.currentPage === 'adduser' && 
            <AddUser
              onClose={this.handleCloseClick}
            />
          }
          {this.state.currentPage === 'edituser' && 
            <EditAdminUser
              onClose={this.handleCloseClick}
              openAddUserPage={this.openAddUserPage}
            />
          }
      </div>
      );
    }
  }
  
  const mapStateToProps = state => {
    return {
      auth: state.auth,
      home: state.home,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        logoutAction,
        getGroups
      },
      dispatch
    );
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader,Segment } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { Scrollbars } from 'rc-scrollbars';

import { GetAllBreakConfig , SaveBreak , EditBreak , DeleteBreak , Active , Deactive } from './../action/breakConfig';
import { isLoggedIn , setupAxios} from '../util';

class Break extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'breakReason', title: 'Break Reason' },
        { name: 'edit', title: 'Edit'},
        { name: 'status', title: 'Status'},
      ],
      columnWidths : [
        { columnName: 'breakReason', width: 600  },
        { columnName: 'edit', width: 200  },
        { columnName: 'status', width: 200  }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      isActive : true,
      showBreakAddModal : false,
      breakReason : '',
      breakReasonError : false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      showBreakEditModal : false,
      breakId : '',
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllBreakConfig();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });

    if(nextProps.break.data && nextProps.break.data.length){

      var rows = nextProps.break.data.map((d) => {

          d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editBreak(d)}><Icon name={'edit'}></Icon></Button>
          d.status = d.isActive ? 'Active' :'Deactive';
          return(d)
    
      });

      this.setState({ rows });
    }else{
      this.setState({ rows : [] });
    }

    if(nextProps.break.isBreakSave){
      this.setState({ successMessage : "Break Added Successfully." , successModal : true });
    }else if(nextProps.break.breakSaveErrorMessage){
      this.setState({ errorMessage : nextProps.break.breakSaveErrorMessage, errorModal : true });
    }
    
    if(nextProps.break.isBreakDelete){
      this.setState({ successMessage : "Break Deleted Successfully." , successModal : true });
    }else if(nextProps.break.breakDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.break.breakDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.break.isBreakEdit){
      this.setState({ successMessage : "Break Updated Successfully." , successModal : true });
    }else if(nextProps.break.breakEditErrorMessage){
      this.setState({ errorMessage : nextProps.break.breakEditErrorMessage, errorModal : true });
    }

    if(nextProps.break.isBreakActive){
      this.setState({ successMessage : "Break Reason Active Successfully." , successModal : true });
    }else if(nextProps.break.breakActiveErrorMessage){
      this.setState({ errorMessage : nextProps.break.breakActiveErrorMessage, errorModal : true });
    }

    if(nextProps.break.isBreakDeactive){
      this.setState({ successMessage : "Break Reason Deactive Successfully." , successModal : true });
    }else if(nextProps.break.breakDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.break.breakDeactiveErrorMessage, errorModal : true });
    }

  }

  deleteBreak = (breakReason) =>{
    this.setState({isUploading : true });
    this.props.DeleteBreak( breakReason.id );
  }

  editBreak = (breakReason) =>{
    this.setState({ breakReason : breakReason.breakReason, showBreakEditModal : true ,breakId : breakReason.id , isActive : breakReason.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  addBreakModal = () =>{
    this.setState({ showBreakAddModal : true });
  }

  handleBreakReasonChange = (breakReason) =>{
    this.setState({ breakReason : breakReason.target.value , breakReasonError : false });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  saveBreak = () =>{

    var breakReason = this.state.breakReason;

    if(breakReason){

      this.setState({isUploading : true });
      this.props.SaveBreak({ breakReason : breakReason });

    }else{

      this.setState({breakReasonError : true });
      
    }

  }

  updateBreak = () =>{

    var breakReason = this.state.breakReason;

    if(breakReason){

      this.setState({isUploading : true });
      this.props.EditBreak({ breakReason : breakReason , breakConfigId : this.state.breakId });
      
    }else{

      this.setState({breakReasonError : true });

    }

  }


  closeBreakCloseModal = () =>{
    this.setState({ showBreakAddModal : false });
  }

  breakEditCloseModal = () =>{
    this.setState({ showBreakEditModal : false , breakReason : ''});
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showBreakAddModal : false , showBreakEditModal : false });
    this.props.GetAllBreakConfig();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  active = ( breakConfigId ) =>{
    this.setState({isUploading : true });
    this.props.Active( breakConfigId );
  }

  deactive = (breakConfigId) =>{

    this.setState({isUploading : true });
    this.props.Deactive( breakConfigId );
  }

  render() {

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addBreakModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add Break Config
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            defaultSorting={[
              { columnName: "breakType", direction: "desc" },
            ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
        <MDBModal isOpen={this.state.showBreakAddModal} centered size="lg">
            <MDBModalHeader>
                Add Break Config
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Break Reason : </b></label>
                <Input onChange={this.handleBreakReasonChange}  placeholder='Enter the Break Reason' style={this.state.breakReasonError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/>                       
                
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeBreakCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveBreak}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showBreakEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Break Config
            </MDBModalHeader>
            <MDBModalBody>

                <label><b> Break Reason : </b></label>
                <Input onChange={this.handleBreakReasonChange} value={this.state.breakReason} placeholder='Enter the Break Reason' style={this.state.breakReasonError?{border: '1px solid red'}:{}} ></Input>
                <br/><br/> 

            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.breakEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateBreak}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactive(this.state.breakId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.active(this.state.breakId) }>Active</Button>
              }
            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      break: state.break
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAllBreakConfig,
    SaveBreak,
    EditBreak,
    DeleteBreak,
    Active,
    Deactive
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Break));
import axios from 'axios';

import {
    GET_ALL_GROUP_SUCCESS,
    GET_ALL_GROUP_FAILURE,
    SAVE_GROUP_SUCCESS,
    SAVE_GROUP_FAILURE,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_FAILURE,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,
    ACTIVE_GROUP_SUCCESS,
    ACTIVE_GROUP_FAILURE,
    DEACTIVE_GROUP_SUCCESS,
    DEACTIVE_GROUP_FAILURE
} from './type.js';

import config from '../config.js';


export const GetAllGroup = () =>{
    return function (dispatch) {

        axios.get( config.api_url+"/group/get" ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}

export const SaveGroup = (data) =>{
    return function (dispatch) {

        axios.post(config.api_url+"/group/add" , data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditGroup = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/group/edit" , data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: EDIT_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: EDIT_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: EDIT_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeleteGroup = (groupId) =>{
    return function (dispatch) {

        axios.delete ( config.api_url+"/group/delete/"+groupId ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DELETE_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DELETE_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DELETE_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}

export const ActiveGroup = (groupId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/group/active" , {groupId : groupId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeactiveGroup = (groupId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/group/deactive" , {groupId : groupId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_GROUP_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_GROUP_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_GROUP_FAILURE,
                    payload: error
                });
            });
    };
}
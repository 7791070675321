import { combineReducers} from 'redux';

import agentReducer from './agent_reducer';
import authReducer from './auth_reducer';
import groupReducer from './group_reducer';
import shiftReducer from './shift_reducer';
import breakReducer from './break_reducer';
import generalIssueReducer from './generalIssue_reducer';
import reportReducer from './report_reducer';
import changepasswordReducer from './changepassword_reducer';
import servicesReducer from './services_reducer';
import addUserReducer from './addUser_reducer';
import forgotpasswordReducer from './forgotpassword_reducer';
import resetpasswordReducer from './resetpassword_reducer';
import stuckProcess from './processStuck_reducer'

const rootReducer = combineReducers({
    agent : agentReducer,
    auth : authReducer ,
    group : groupReducer ,
    shift : shiftReducer,
    break : breakReducer,
    generalIssue : generalIssueReducer,
    report : reportReducer,
    changePassword : changepasswordReducer,
    services : servicesReducer,
    addUser : addUserReducer,
    forgotpassword : forgotpasswordReducer,
    resetpassword : resetpasswordReducer,
    stuckEmails:stuckProcess
});

export default rootReducer;
import React,{Component} from 'react';
import { Button ,Input , Dimmer,Loader, Icon, Select } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isLoggedIn } from '../util';
import { AddUserAction } from '../action/addUser.js';
import {  MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import { Scrollbars } from 'rc-scrollbars';

import passwordValidator from 'password-validator';

 class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role:'User',
            name : '',
            validName : false,
            email : '',
            validEmail : false,
            newPassword : '',
            validNewPassword : false,
            confirmPassword : '',
            validConfirmPassword : false,
            mobileNumber : '',
            validMobileNumber : false,
            showAddUserConfirmModal : false,
            showSuccesModal : false,
            showFailureModal : false,
            successMsg : '',
            failureMsg : '',
            passwordNotMached : false,
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    componentWillReceiveProps(nextProps) {

        if (!isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/login`);
        }

        if(nextProps.addUser.addUser){
            this.setState({
                showAddUserConfirmModal : false ,
                showSuccesModal : true,
                successMsg : "User Added Successfully."
            })
        }else if(nextProps.addUser.addUserErrorMessage){
            this.setState({
                showAddUserConfirmModal : false ,
                showFailureModal : true,
                failureMsg : nextProps.addUser.addUserErrorMessage
            })
        }
        this.setState({isLoadingFlag : false});
    }

    componentWillMount() {
        if (!isLoggedIn(this.props.auth)) {
            this.props.history.push(`/login`);
        }
    }

    shouldComponentUpdate(nextProps, nextState) { 
        if (!isLoggedIn(nextProps.auth)) {
            this.props.history.push(`/login`);
            return false;
        }
        return true;
    }

    nameOnChange = (e) =>{
        this.setState({
            name : e.target.value,
            validName : false
        })
    }

    roleOnChange = (e,data) =>{
        this.setState({ role : data.value });
    }

    emailOnChange = (e) =>{
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(e.target.value);
        if(result){
          this.setState({
            email : e.target.value,
            validEmail : false
          })
        } else{
          this.setState({
            email : e.target.value,

            validEmail:true
          })
        }
    }

    mobileNumberOnChange = (e) =>{
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({
                mobileNumber : e.target.value,
                validMobileNumber : false
            })
        }
    }
    newPasswordOnChange = (e) =>{
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  

        if( e.target.value.length <= 24 ){
            var checkPassword = schema.validate(e.target.value);
            if(checkPassword){
                this.setState({
                    newPassword : e.target.value,
                    validNewPassword : false
                }) 
            }else{
                this.setState({
                    newPassword : e.target.value,
                    validNewPassword : true
                }) 
            }
        }
    }

    confirmPasswordOnChange = (e) =>{

        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  
    
        if( e.target.value.length <= 24 ){

            var checkPassword = schema.validate(e.target.value);
        
            if(checkPassword){
    
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : false,
                    passwordNotMached : false
                }) 
    
            }else{
    
                this.setState({
                    confirmPassword : e.target.value,
                    validConfirmPassword : true
                }); 
    
            }
            
        }

    }

    addUserConfirm = () =>{
        var schema = new passwordValidator();
        schema
        .is().min(8)
        .is().max(24)
        .has().uppercase()
        .has().lowercase()        
        .has().digits(1) 
        .has().not().spaces()
        .has().symbols()  

        var name = this.state.name;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        var email = pattern.test(this.state.email);
        var mobileNumber = this.state.mobileNumber;
        var checkNewPassword = schema.validate(this.state.newPassword);
        var checkConfirmPassword = schema.validate(this.state.confirmPassword);
        if( name && email && mobileNumber && checkNewPassword && checkConfirmPassword && this.state.newPassword === this.state.confirmPassword){
            this.setState({showAddUserConfirmModal : true});
        }else{
            if(!name){
                this.setState({validName : true});
            }
            if(!email){
                this.setState({validEmail : true});
            }
            if(!mobileNumber){
                this.setState({validMobileNumber : true});
            }
            if(!checkNewPassword){
                this.setState({validNewPassword : true});
            }
            if(!checkConfirmPassword){
                this.setState({validConfirmPassword : true});
            }
            if(this.state.newPassword !== this.state.confirmPassword ){
                this.setState({passwordNotMached : true});
            }
        }
    }

    closeAddUserConfirmModal = () =>{
        this.setState({
            showAddUserConfirmModal : false
        })  
    }

    addUser = () =>{
        this.setState({isLoadingFlag : true });
        this.props.AddUserAction({ name : this.state.name , email : this.state.email , password : this.state.newPassword , role : this.state.role , mobileNumber : this.state.mobileNumber });       
    }

    closeSuccessModal = () =>{
        this.setState({
            showAddUserConfirmModal : false,
            showSuccesModal : false,
            name : '',
            email : '',
            role: 'User',
            mobileNumber : '',
            newPassword : '',
            confirmPassword : ''
        })
    }

    closeFailureModal = () =>{
        this.setState({
            showFailureModal : false,
            showAddUserConfirmModal : false
        }) 
    }

    
    render(){
        var roleArray = [
            {
              key : 1,
              text : 'User',
              value : 'User'
            },
            {
              key : 2,
              text : 'Admin',
              value : 'Admin'
            }
          ]
        return( 
            <div id="content"> 
                {this.state.isLoadingFlag && 
                    <Dimmer active>
                        <Loader size="large">Loading</Loader>
                    </Dimmer>
                } 
                <Scrollbars style={{ height:  this.state.height - 200  }}>
                <div>
                    <h2>Add User</h2>
                    <hr/>
                    <br/><br/>
                    <div style={{paddingLeft :30}}>
                        <label><b>User Name :</b></label>
                        <br/><br/>
                            <Input value={this.state.name} style={this.state.validName ? {border:'1px solid red' ,width:'50%'} : {width:'50%'}} onChange={this.nameOnChange} placeholder='Enter the Name'></Input>
                            <br/>
                            <label style={this.state.validName ? {color:'red'} : {display:'none'}}>Please Enter Name.</label>
                        <br/><br/>
                        <label><b>Email:</b></label>
                        <br/><br/>
                            <Input value={this.state.email} style={this.state.validEmail ? {border:'1px solid red' ,width:'50%'} : {width:'50%'}} onChange={this.emailOnChange} placeholder='Enter the Email'></Input>
                            <br/>
                            <label style={this.state.validEmail ? {color:'red'} : {display:'none'}}>Please Enter Email.</label>
                        <br/><br/>
                        <label><b>Mobile Number:</b></label>
                        <br/><br/>
                            <Input  value={this.state.mobileNumber} style={this.state.validMobileNumber ? {border:'1px solid red' ,width:'50%'} : {width:'50%'}} onChange={this.mobileNumberOnChange} placeholder='Enter the Mobile Number'></Input>
                            <br/>
                            <label style={this.state.validMobileNumber ? {color:'red'} : {display:'none'}}>Please Enter Mobile Number.</label>
                        <br/><br/>
                        <label><b>Role:</b></label>
                        <br/><br/>
                            <Select value={this.state.role} options={roleArray} onChange={this.roleOnChange} placeholder='Select Role' style={{width:'50%'}}></Select> 
                        <br/><br/>
                        <label><b>Password :</b></label>
                        <br/><br/>
                            <Input style={this.state.validNewPassword ? {border:'1px solid red',width:'50%'} : {width:'50%'}} onChange={this.newPasswordOnChange} value={this.state.newPassword}  placeholder='Enter the new password'></Input>
                            <br/>
                            <label style={this.state.validNewPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                        <br/><br/>
                        <label><b>Confirm Password :</b></label>
                        <br/><br/>
                            <Input style={this.state.validConfirmPassword || this.state.passwordNotMached ? {border:'1px solid red',width:'50%'} : {width:'50%'}} value={this.state.confirmPassword} onChange={this.confirmPasswordOnChange} placeholder='Enter the confirm password'></Input>
                            <br/>
                            <label style={this.state.validConfirmPassword ? {color:'red'} : {display:'none'}}>Password does not match the password Policy rules. Please include 1 special character, 1 upper case, 1 number , min 8 characters in your password.</label>
                            <label style={this.state.passwordNotMached ? {color:'red'} : {display:'none'}}>Password Not Matched.</label>
                        <br/><br/>
                            <Button  onClick={this.addUserConfirm} style={{backgroundColor:"#03a5fc" ,color:"white",padding:10}}>Submit</Button>
                    </div>
                </div>
                </Scrollbars>
                <MDBModal isOpen={this.state.showAddUserConfirmModal} centered size="lg"> 
                    <MDBModalHeader>Add User</MDBModalHeader>
                    <MDBModalBody>
                        <p>Are you sure you want to add User?</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <Button negative onClick={this.closeAddUserConfirmModal}>Cancel</Button>
                        <Button color='blue' content='Add' onClick={this.addUser}/>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.showSuccesModal}
                    centered
                >
                    <MDBModalHeader>
                        <h3>{this.state.successMsg}</h3>
                    </MDBModalHeader>
                    <MDBModalFooter>
                        <Button color='blue' onClick={this.closeSuccessModal} inverted>
                            <Icon name='checkmark' /> Ok
                        </Button>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal
                    isOpen={this.state.showFailureModal}
                    centered
                >
                    <MDBModalHeader>
                        <h3>{this.state.failureMsg}</h3>
                    </MDBModalHeader>
                    <MDBModalFooter>
                        <Button color='red' onClick={this.closeFailureModal} inverted>
                            <Icon name='checkmark' /> Ok
                        </Button>
                    </MDBModalFooter>
                </MDBModal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        addUser : state.addUser
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            AddUserAction
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddUser)
);
import {
    GET_ALL_BREAK_CONFIG_SUCCESS,
    GET_ALL_BREAK_CONFIG_FAILURE,
    SAVE_BREAK_CONFIG_SUCCESS,
    SAVE_BREAK_CONFIG_FAILURE,
    EDIT_BREAK_CONFIG_SUCCESS,
    EDIT_BREAK_CONFIG_FAILURE,
    DELETE_BREAK_CONFIG_SUCCESS,
    DELETE_BREAK_CONFIG_FAILURE,
    ACTIVE_BREAK_SUCCESS,
    ACTIVE_BREAK_FAILURE,
    DEACTIVE_BREAK_SUCCESS,
    DEACTIVE_BREAK_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state   , action ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_BREAK_CONFIG_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_BREAK_CONFIG_FAILURE:
            state = {
                isBreakGetError : true,
                breakGetErrorMessage : action.payload.message
            }
            return state;
        case SAVE_BREAK_CONFIG_SUCCESS:
            state = {
                isBreakSave : true
            };
            return state;

        case SAVE_BREAK_CONFIG_FAILURE:
            state = {
                isBreakSave : false,
                breakSaveErrorMessage : action.payload.message
            }
            return state;
        case DELETE_BREAK_CONFIG_SUCCESS:
            state = {
                isBreakDelete : true
            };
            return state;

        case DELETE_BREAK_CONFIG_FAILURE:
            state = {
                isBreakDelete : false,
                breakDeleteErrorMessage : action.payload.message
            }
            return state;

        case EDIT_BREAK_CONFIG_SUCCESS:
            state = {
                isBreakEdit : true
            };
            return state;

        case EDIT_BREAK_CONFIG_FAILURE:
            state = {
                isBreakEdit : false,
                breakEditErrorMessage : action.payload.message
            }
            return state;
        case ACTIVE_BREAK_SUCCESS:
            state = {
                isBreakActive : true
            };
            return state;

        case ACTIVE_BREAK_FAILURE:
            state = {
                isBreakActive : false,
                breakActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_BREAK_SUCCESS:
            state = {
                isBreakDeactive : true
            };
            return state;

        case DEACTIVE_BREAK_FAILURE:
            state = {
                isBreakDeactive : false,
                breakDeactiveErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import axios from 'axios';

import {
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    GET_ALL_ADMIN_USER_SUCCESS,
    GET_ALL_ADMIN_USER_FAILURE,
    EDIT_ADMIN_USER_SUCCESS,
    EDIT_ADMIN_USER_FAILURE
} from './type.js';

import config from './../config.js';

export const AddUserAction = (data) =>{
    return function (dispatch) {
        axios.post(config.api_url+"/adminUser/add" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: ADD_USER_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: ADD_USER_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: ADD_USER_FAILURE,
                payload: error
            });
        });
    };
}

export const GetAdminUser = () =>{
    return function (dispatch) {
        axios.get(config.api_url+"/adminUser/get" ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: GET_ALL_ADMIN_USER_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: GET_ALL_ADMIN_USER_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: GET_ALL_ADMIN_USER_FAILURE,
                payload: error
            });
        });
    };
}

export const EditAdminUserAction = (data) =>{
    return function (dispatch) {
        axios.post(config.api_url+"/adminUser/edit" , data ).then(function (response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: EDIT_ADMIN_USER_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: EDIT_ADMIN_USER_FAILURE,
                    payload: response.data
                });
            }
        }).catch(function (error) {
            dispatch({
                type: EDIT_ADMIN_USER_FAILURE,
                payload: error
            });
        });
    };
}
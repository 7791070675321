import axios from 'axios';

import {
   GET_ALL_REPORT_CONFIG_SUCCESS,
   GET_ALL_REPORT_CONFIG_FAILURE,
   SAVE_REPORT_CONFIG_SUCCESS,
   SAVE_REPORT_CONFIG_FAILURE,
   EDIT_REPORT_CONFIG_SUCCESS,
   EDIT_REPORT_CONFIG_FAILURE,
   DELETE_REPORT_CONFIG_SUCCESS,
   DELETE_REPORT_CONFIG_FAILURE,
   ACTIVE_REPORT_CONFIG_SUCCESS,
   ACTIVE_REPORT_CONFIG_FAILURE,
   DEACTIVE_REPORT_CONFIG_SUCCESS,
   DEACTIVE_REPORT_CONFIG_FAILURE
} from './type.js';

import config from '../config.js';

export const GetAllReportConfig = () =>{
    return function (dispatch) {

        axios.get (config.api_url+"/config/report/get").then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const SaveReport = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/report/add", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditReport = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/report/edit", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: EDIT_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: EDIT_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: EDIT_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeleteReport = (reportId) =>{
    return function (dispatch) {

        axios.delete( config.api_url+"/config/report/delete/"+reportId ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DELETE_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DELETE_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DELETE_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const Active = (reportId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/report/active" , {reportId : reportId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const Deactive = (reportId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/report/deactive" , {reportId : reportId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_REPORT_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_REPORT_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_REPORT_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

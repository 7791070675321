import {
    FORCELOGOUT_AGENT_SUCCESS,
    FORCELOGOUT_AGENT_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function ( state, action  ) {
    if (typeof state === 'undefined') {
        state = INIT_STATE
    }
    switch (action.type) {
        
        case FORCELOGOUT_AGENT_SUCCESS:
            state = {
                isForgotPassword : true
            } ;
            return state;
        case FORCELOGOUT_AGENT_FAILURE:
            state = {
                forgotPasswordErrorMessage : action.payload.message,
                isForgotPassword : false
            } ;
            return state;
        default :
            return state;
    }
}
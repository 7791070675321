import {
    GET_ALL_GROUP_SUCCESS,
    GET_ALL_GROUP_FAILURE,
    SAVE_GROUP_SUCCESS,
    SAVE_GROUP_FAILURE,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_FAILURE,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,
    ACTIVE_GROUP_SUCCESS,
    ACTIVE_GROUP_FAILURE,
    DEACTIVE_GROUP_SUCCESS,
    DEACTIVE_GROUP_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state , action  ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_GROUP_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_GROUP_FAILURE:
            state = {
                isGroupGetError : true,
                groupGetErrorMessage : action.payload.message
            }
            return state;
        case SAVE_GROUP_SUCCESS:
            state = {
                isGroupSave : true
            };
            return state;

        case SAVE_GROUP_FAILURE:
            state = {
                isGroupSave : false,
                groupSaveErrorMessage : action.payload.message
            }
            return state;

        case DELETE_GROUP_SUCCESS:
            state = {
                isGroupDelete : true
            };
            return state;

        case DELETE_GROUP_FAILURE:
            state = {
                isGroupDelete : false,
                groupDeleteErrorMessage : action.payload.message
            }
            return state;

        case EDIT_GROUP_SUCCESS:
            state = {
                isGroupEdit : true
            };
            return state;

        case EDIT_GROUP_FAILURE:
            state = {
                isGroupEdit : false,
                groupEditErrorMessage : action.payload.message
            }
            return state;

        case ACTIVE_GROUP_SUCCESS:
            state = {
                isGroupActive : true
            };
            return state;

        case ACTIVE_GROUP_FAILURE:
            state = {
                isGroupActive : false,
                groupActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_GROUP_SUCCESS:
            state = {
                isGroupDeactive : true
            };
            return state;

        case DEACTIVE_GROUP_FAILURE:
            state = {
                isGroupDeactive : false,
                groupDeactiveErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button , Icon , Label , Input , Dimmer , Loader ,Segment, Dropdown} from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter , MDBModalHeader} from "mdbreact";

import {
  SortingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SearchState
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';

import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { Scrollbars } from 'rc-scrollbars';

import { GetAllReportConfig , SaveReport , EditReport , DeleteReport , Active , Deactive } from './../action/reportConfig';
import { isLoggedIn , setupAxios} from '../util';

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'period', title: 'Period' },
        { name: 'toMailIdsString', title: 'To MailIds' },
        { name: 'ccMailIdsString', title: 'CC MailIds' },
        { name: 'bccMailIdsString', title: 'Bcc MailIds' },
        { name: 'edit', title: 'Edit'},
        { name: 'status', title: 'Status'},
      ],
      columnWidths : [
        { columnName: 'period', width: 200  },
        { columnName: 'toMailIdsString', width: 200 },
        { columnName: 'ccMailIdsString', width: 200  },
        { columnName: 'bccMailIdsString', width: 200 },
        { columnName: 'edit', width: 100  },
        { columnName: 'status', width: 100  }
      ],
      rows: [],
      grouping :[],
      pageSizes: [5, 10, 15, 0],
      pageSize : 5,
      currentPage : 0,
      showReportAddModal : false,
      toIds : [""],
      toIdsError : false,
      ccIds : [""],
      ccIdsError : false,
      bccIds : [""],
      period: "daily",
      bccIdsError : false,
      successMessage : '',
      errorMessage : '',
      successModal : false,
      errorModal : false,
      isUploading : false ,
      showReportEditModal : false,
      reportId : '',
      isActive : true,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    this.setState({isUploading : true });
    setupAxios(this.props.auth.accessToken);
    this.props.GetAllReportConfig();
  }

  componentWillReceiveProps(nextProps){
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
    }
    
    this.setState({isUploading : false });

    if(nextProps.report.data && nextProps.report.data.length){

      var rows = nextProps.report.data.map((d) => {

        d.toIds = JSON.parse(d.toIds);
        d.ccIds = JSON.parse(d.ccIds);
        d.bccIds = JSON.parse(d.bccIds);
        d.toMailIdsString = d.toIds.join(',');
        d.ccMailIdsString = d.ccIds.join(',');
        d.bccMailIdsString = d.bccIds.join(',');

        d.edit =  <Button style={{backgroundColor : 'blue' , color: 'black'}} onClick={() => this.editReport(d)}><Icon name={'edit'}></Icon></Button>
        d.status = d.isActive ? 'Active': 'Deactive';
        return(d)
    
      });

      this.setState({ rows });
    }else{
      this.setState({ rows : [] });
    }

    if(nextProps.report.isReportSave){
      this.setState({ successMessage : "Report Added Successfully." , successModal : true });
    }else if(nextProps.report.reportSaveErrorMessage){
      this.setState({ errorMessage : nextProps.report.reportSaveErrorMessage, errorModal : true });
    }
    
    if(nextProps.report.isReportDelete){
      this.setState({ successMessage : "Report Deleted Successfully." , successModal : true });
    }else if(nextProps.report.reportDeleteErrorMessage){
      this.setState({ errorMessage : nextProps.report.reportDeleteErrorMessage, errorModal : true });
    }

    if(nextProps.report.isReportEdit){
      this.setState({ successMessage : "Report Updated Successfully." , successModal : true });
    }else if(nextProps.report.reportEditErrorMessage){
      this.setState({ errorMessage : nextProps.report.reportEditErrorMessage, errorModal : true });
    }

    if(nextProps.report.isReportActive){
      this.setState({ successMessage : "Report Active Successfully." , successModal : true });
    }else if(nextProps.report.reportActiveErrorMessage){
      this.setState({ errorMessage : nextProps.report.reportActiveErrorMessage, errorModal : true });
    }

    if(nextProps.report.isReportDeactive){
      this.setState({ successMessage : "Report Deactive Successfully." , successModal : true });
    }else if(nextProps.report.reportDeactiveErrorMessage){
      this.setState({ errorMessage : nextProps.report.reportDeactiveErrorMessage, errorModal : true });
    }

  }

  deleteReport = (reportReason) =>{
    this.setState({isUploading : true });
    this.props.DeleteReport( reportReason.id );
  }

  editReport = (reportReason) =>{
    this.setState({  period : reportReason.period, toIds : reportReason.toIds, ccIds : reportReason.ccIds ,  bccIds : reportReason.bccIds,showReportEditModal : true ,reportId : reportReason.id , isActive : reportReason.isActive });
  }

  setGrouping = (grouping) =>{
    this.setState({grouping});
  }

  pageSizeChange = (pageSize) =>{
    this.setState({pageSize});
  }

  onCurrentPageChange = (currentPage) =>{
    this.setState({currentPage});
  }

  addReportModal = () =>{
    this.setState({ showReportAddModal : true });
  }

  handleReportReasonChange = (reportReason) =>{
    this.setState({ reportReason : reportReason.target.value , reportReasonError : false });
  }

  handlePeriodChange = (period) =>{
    console.log(period)
    this.setState({ period });
  }

  saveReport = () =>{

    var toIds = this.state.toIds;
    var ccIds = this.state.ccIds;
    var bccIds = this.state.bccIds;

    if(toIds[0] ){
      this.setState({isUploading : true });
      this.props.SaveReport({ toIds : toIds , ccIds: ccIds, bccIds:bccIds , period : this.state.period });
    }else{

      if(!toIds[0]){
        this.setState({toIdsError : true });
      }
     
    }

  }

  updateReport = () =>{

    var toIds = this.state.toIds;
    var ccIds = this.state.ccIds;
    var bccIds = this.state.bccIds;


    if(toIds[0]  ){
      this.setState({isUploading : true });
      this.props.EditReport({ toIds : toIds , ccIds: ccIds ,  bccIds:bccIds , period : this.state.period ,reportConfigId : this.state.reportId });
    }else{

      if(!toIds[0]){
        this.setState({toIdsError : true });
      }
     
    }

  }


  closeReportCloseModal = () =>{
    this.setState({ showReportAddModal : false });
  }

  reportEditCloseModal = () =>{
    this.setState({ showReportEditModal : false , reportReason : ''});
  }

  successCloseButton = () =>{
    this.setState({ successModal : false , showReportAddModal : false , showReportEditModal : false });
    this.props.GetAllReportConfig();
  }

  errorCloseButton = () =>{
    this.setState({ errorModal : false });
  }

  handleToChange = (e,index) =>{
    let toIds = this.state.toIds;
    toIds[index] = e.target.value;
    this.setState({ toIds , toError : false})
  }

  addToMail = () =>{
    var toIds = this.state.toIds;
    toIds.push("");
    this.setState({toIds})
  }

  deleteToMail = (index) =>{
    let toIds = this.state.toIds;
    toIds.splice(index, 1);
    this.setState({ toIds });
  }


  handleCCChange = (e,index) =>{
      let ccIds = this.state.ccIds;
      ccIds[index] = e.target.value;
      this.setState({ ccIds , ccError : false})
  }

  addCCMail = () =>{
    var ccIds = this.state.ccIds;
    ccIds.push("");
    this.setState({ccIds});
  }

  deleteCCMail = (index) =>{
    let ccIds = this.state.ccIds;
    ccIds.splice(index, 1);
    this.setState({ ccIds });
  }

  handleBccChange = (e,index) =>{
    let bccIds = this.state.bccIds;
    bccIds[index] = e.target.value;
    this.setState({ bccIds , bccError : false})
  }

  addBccMail = () =>{
    var bccIds = this.state.bccIds;
    bccIds.push("");
    this.setState({bccIds});
  }

  deleteBccMail = (index) =>{
    let bccIds = this.state.bccIds;
    bccIds.splice(index, 1);
    this.setState({ bccIds });
  }

  setColumnWidths = ( widths ) =>{
    this.setState({columnWidths : widths });
  }

  active = (reportId) =>{
    this.setState({isUploading : true });
    this.props.Active( reportId );
  }

  deactive = (reportId) =>{

    this.setState({isUploading : true });
    this.props.Deactive( reportId );
  }

  render() {

    return (
      <div id="content">
        <Dimmer active={this.state.isUploading}>
            <Loader size="large" active inline>
            </Loader>
        </Dimmer>
        <Segment onClick={this.props.onClose} style={{
              color: '#808080',           
              float: 'right',
              cursor: 'pointer',
              marginTop: 0,
              position: 'absolute',
              right: 19
          }}>
          <Icon name="arrow left" className="left large" color="brown"/> 
        </Segment>
        <div style={{margin : 20}}>
          <Label
              color="green"
              onClick={this.addReportModal}
              tag
              pointing="left"
              size="large"
              style={{ cursor: "pointer"}}
          >
              <Icon name="add" /> Add Report Config
          </Label>
        </div>
        <Scrollbars style={{ height:  this.state.height - 200  }}>
        <div>
          <Grid
            rows={this.state.rows}
            columns={this.state.columns}
          >
          <SortingState
            defaultSorting={[
              { columnName: "reportType", direction: "desc" },
            ]}
          />
          <IntegratedSorting />
          <PagingState
            defaultCurrentPage={0}
            pageSize={this.state.pageSize}
            currentPage={this.state.currentPage}
            onCurrentPageChange={this.onCurrentPageChange}
            onPageSizeChange={this.pageSizeChange}
          />
          <IntegratedPaging />
            <DragDropProvider />
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.setGrouping}
            />
            <IntegratedGrouping />
            {/* <FilteringState defaultFilters={[]} />
            <IntegratedFiltering /> */}
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableColumnResizing
              columnWidths={this.state.columnWidths}
              onColumnWidthsChange={this.setColumnWidths}
            />
            <TableHeaderRow showSortingControls/>
            <TableGroupRow />
            <Toolbar />
            {/* <TableFilterRow /> */}
            <GroupingPanel showGroupingControls showSortingControls/>
            <PagingPanel pageSizes={this.state.pageSizes} />
            <SearchPanel/>
          </Grid>
        </div>
        </Scrollbars>
        <MDBModal isOpen={this.state.showReportAddModal} centered size="lg">
            <MDBModalHeader>
                Add Report Config
            </MDBModalHeader>
            <MDBModalBody>
                <label><b> Period : </b></label>
                {/* <Dropdown pointing text={this.state.period}
                  label="Period"
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Yearly', value: 'yearly' },
                  ]}
                  value={this.state.period}
                  onChange={this.handlePeriodChange(e)}
                /> */}
                <Dropdown pointing text={this.state.period} value={this.state.period}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('daily')}>Daily</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('weekly')}>Weekly</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('monthly')}>Monthly</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('yearly')}>Yearly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <br/><br/>
                <label><b> To MailIds : </b></label>
                {this.state.toIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.toIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleToChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.toIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteToMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleToChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteToMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addToMail() }}
                    />
                </div>  
                <br/>
                <label><b> CC MailIds : </b></label>
                {this.state.ccIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.ccIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleCCChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.ccIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteCCMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleCCChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteCCMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addCCMail() }}
                    />
                </div>                   
                <br/>
                <label><b> Bcc MailIds : </b></label>
                {this.state.bccIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.bccIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleBccChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.bccIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteBccMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleBccChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteBccMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addBccMail() }}
                    />
                </div>  
                <br/>
               
            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.closeReportCloseModal}>Close</Button>
              <Button color='blue' content='Save' onClick={this.saveReport}/>

            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.showReportEditModal} centered size="lg">
            <MDBModalHeader>
                Edit Report Config
            </MDBModalHeader>
            <MDBModalBody>
              <label><b> Period : </b></label>
                {/* <Dropdown
                  label="Period"
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Yearly', value: 'yearly' },
                  ]}
                  value={this.state.period}
                  onChange={this.handlePeriodChange}
                /> */}
              <Dropdown pointing text={this.state.period} value={this.state.period}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('daily')}>Daily</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('weekly')}>Weekly</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('monthly')}>Monthly</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handlePeriodChange('yearly')}>Yearly</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
              <br/><br/>
              <label><b> To MailIds : </b></label>
                {this.state.toIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.toIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleToChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.toIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteToMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleToChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteToMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addToMail() }}
                    />
                </div>  
                <br/>
              <label><b> CC MailIds : </b></label>
                {this.state.ccIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.ccIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleCCChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.ccIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteCCMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleCCChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteCCMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addCCMail() }}
                    />
                </div>    
              <br/>
              <label><b> BCC MailIds : </b></label>
                {this.state.bccIds.map((_, index) => {
                    if (index === 0) {
                        return (
                            <div key={index} style={{ display: 'flex'}} >
                                <Input style={this.state.bccIdsError ? { width: '100%' ,border:'1px solid red'}:{width: '100%' }} value={_} onChange={(e) => this.handleBccChange(e, index)} placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={this.state.bccIds.length <= 1 ? { display: 'none' }:{ padding: 10 , marginLeft : 6,color:"white"  , backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteBccMail(index) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} style={{ display: 'flex', paddingTop: 10 }}>
                                <Input style={{ width: '100%' }} value={_} onChange={(e) => this.handleBccChange(e, index)}  placeholder='Enter the Email Id' ></Input>
                                <Button 
                                    style={{ paddingLeft: 10 , color:"white"  ,marginLeft : 6, backgroundColor : 'red'}}
                                    icon="delete" onClick={() => { this.deleteBccMail(index) }}
                                />
                            </div>
                        )
                    }
                })} 
                <div style={{paddingTop:10}}>
                    <Button 
                        style={{ padding: 10 , color:"white"  , backgroundColor : 'green'}}
                        icon="add" onClick={() => { this.addBccMail() }}
                    />
                </div>  
                <br/>

            </MDBModalBody>

            <MDBModalFooter>

              <Button negative onClick={this.reportEditCloseModal}>Close</Button>
              <Button color='blue' content='Update' onClick={this.updateReport}/>
              {this.state.isActive ?
                <Button style={{backgroundColor : 'red' , color : 'white'}} onClick={ () => this.deactive(this.state.reportId) }>Deactive</Button> :
                <Button style={{backgroundColor : 'green' , color : 'white'}} onClick={ () => this.active(this.state.reportId) }>Active</Button>
              }
            </MDBModalFooter>

        </MDBModal>

        <MDBModal isOpen={this.state.successModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.successMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.errorModal} centered size="sm">
            <MDBModalBody className="text-center">
                {this.state.errorMessage}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      report: state.report
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GetAllReportConfig,
    SaveReport,
    EditReport,
    DeleteReport,
    Active,
    Deactive
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agent));
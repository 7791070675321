import {
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    GET_ALL_ADMIN_USER_SUCCESS,
    GET_ALL_ADMIN_USER_FAILURE,
    EDIT_ADMIN_USER_SUCCESS,
    EDIT_ADMIN_USER_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function ( state  , action  ) {
    if (typeof state === 'undefined') {
        state = INIT_STATE
    }
    switch (action.type) {
        
        case ADD_USER_SUCCESS:
            state = {
                addUser : true
            } ;
            return state;
        case ADD_USER_FAILURE:
            state = {
                addUserErrorMessage : action.payload.message,
                addUser : false
            } ;
            return state;
        case GET_ALL_ADMIN_USER_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_ADMIN_USER_FAILURE:
            state = {
                isAdminUserGetError : true,
                agentGetErrorMessage : action.payload.message
            }
            return state;
        case EDIT_ADMIN_USER_SUCCESS:
            state = {
                isAdminUserEdit : true
            };
            return state;

        case EDIT_ADMIN_USER_FAILURE:
            state = {
                isAdminUserEdit : false,
                adminUserEditErrorMessage : action.payload.message
            }
            return state;
        default :
            return state;
    }
}
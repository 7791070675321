import axios from 'axios';

import {
   GET_ALL_GENERALISSUE_CONFIG_SUCCESS,
   GET_ALL_GENERALISSUE_CONFIG_FAILURE,
   SAVE_GENERALISSUE_CONFIG_SUCCESS,
   SAVE_GENERALISSUE_CONFIG_FAILURE,
   EDIT_GENERALISSUE_CONFIG_SUCCESS,
   EDIT_GENERALISSUE_CONFIG_FAILURE,
   DELETE_GENERALISSUE_CONFIG_SUCCESS,
   DELETE_GENERALISSUE_CONFIG_FAILURE,
   ACTIVE_GENERALISSUE_SUCCESS,
   ACTIVE_GENERALISSUE_FAILURE,
   DEACTIVE_GENERALISSUE_SUCCESS,
   DEACTIVE_GENERALISSUE_FAILURE
} from './type.js';

import config from '../config.js';

export const GetAllGeneralIssueConfig = () =>{
    return function (dispatch) {

        axios.get( config.api_url+"/config/generalIssueReason/get" ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: GET_ALL_GENERALISSUE_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: GET_ALL_GENERALISSUE_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: GET_ALL_GENERALISSUE_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const SaveGeneralIssue = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/generalIssueReason/add", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: SAVE_GENERALISSUE_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: SAVE_GENERALISSUE_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: SAVE_GENERALISSUE_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const EditGeneralIssue = (data) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/generalIssueReason/edit", data ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: EDIT_GENERALISSUE_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: EDIT_GENERALISSUE_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: EDIT_GENERALISSUE_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const DeleteGeneralIssue = (generalIssueId) =>{
    return function (dispatch) {

        axios.delete ( config.api_url+"/config/generalIssueReason/delete/"+generalIssueId ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DELETE_GENERALISSUE_CONFIG_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DELETE_GENERALISSUE_CONFIG_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DELETE_GENERALISSUE_CONFIG_FAILURE,
                    payload: error
                });
            });
    };
}

export const Active = (generalIssueId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/generalIssueReason/active" , {generalIssueId : generalIssueId } ).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: ACTIVE_GENERALISSUE_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: ACTIVE_GENERALISSUE_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: ACTIVE_GENERALISSUE_FAILURE,
                    payload: error
                });
            });
    };
}

export const Deactive = (generalIssueId) =>{
    return function (dispatch) {

        axios.post( config.api_url+"/config/generalIssueReason/deactive" , {generalIssueId : generalIssueId }).then(function (response) {
                if(response.data.statusCode === 200){
                    dispatch({
                        type: DEACTIVE_GENERALISSUE_SUCCESS,
                        payload: response.data
                    });
                }else{
                    dispatch({
                        type: DEACTIVE_GENERALISSUE_FAILURE,
                        payload: response.data
                    });
                }
            }).catch(function (error) {
                dispatch({
                    type: DEACTIVE_GENERALISSUE_FAILURE,
                    payload: error
                });
            });
    };
}

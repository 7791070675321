import {
    GET_ALL_AGENT_SUCCESS,
    GET_ALL_AGENT_FAILURE,
    SAVE_AGENT_SUCCESS,
    SAVE_AGENT_FAILURE,
    DELETE_AGENT_SUCCESS,
    DELETE_AGENT_FAILURE,
    EDIT_AGENT_SUCCESS,
    EDIT_AGENT_FAILURE,
    FORCELOGOUT_AGENT_SUCCESS,
    FORCELOGOUT_AGENT_FAILURE,
    ACTIVE_AGENT_SUCCESS,
    ACTIVE_AGENT_FAILURE,
    DEACTIVE_AGENT_FAILURE,
    DEACTIVE_AGENT_SUCCESS
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state  , action ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_ALL_AGENT_SUCCESS:
            state = action.payload;
            return state;

        case GET_ALL_AGENT_FAILURE:
            state = {
                isAgentGetError : true,
                agentGetErrorMessage : action.payload.message
            }
            return state;

        case SAVE_AGENT_SUCCESS:
            state = {
                isAgentSave : true
            };
            return state;

        case SAVE_AGENT_FAILURE:
            state = {
                isAgentSave : false,
                agentSaveErrorMessage : action.payload.message
            }
            return state;

        case DELETE_AGENT_SUCCESS:
            state = {
                isAgentDelete : true
            };
            return state;

        case DELETE_AGENT_FAILURE:
            state = {
                isAgentDelete : false,
                agentDeleteErrorMessage : action.payload.message
            }
            return state;

        case ACTIVE_AGENT_SUCCESS:
            state = {
                isAgentActive : true
            };
            return state;

        case ACTIVE_AGENT_FAILURE:
            state = {
                isAgentActive : false,
                agentActiveErrorMessage : action.payload.message
            }
            return state;

        case DEACTIVE_AGENT_SUCCESS:
            state = {
                isAgentDeactive : true
            };
            return state;

        case DEACTIVE_AGENT_FAILURE:
            state = {
                isAgentDeactive : false,
                agentDeactiveErrorMessage : action.payload.message
            }
            return state;

        case EDIT_AGENT_SUCCESS:
            state = {
                isAgentEdit : true
            };
            return state;

        case EDIT_AGENT_FAILURE:
            state = {
                isAgentEdit : false,
                agentEditErrorMessage : action.payload.message
            }
            return state;

        case FORCELOGOUT_AGENT_SUCCESS:
            state = {
                isAgentForceLogout : true
            };
            return state;

        case FORCELOGOUT_AGENT_FAILURE:
            state = {
                isAgentForceLogout : false,
                agentForceLogoutErrorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }

}
import moment from 'moment';
import axios from 'axios';

export function isLoggedIn(authState) {
    try {
        if (!authState.accessToken)
            return false;
        if (moment(authState.expiresIn).isBefore(moment())) {
            return false;
        }

        return true;
    } catch (error) {
        return false;
    }
}

export const setupAxios = (accessToken) => {
    axios.defaults.headers.common['x-email-management-auth'] = "Bearer " + accessToken;
}

export const loadState = () => {
    try {
        const serialisedState = localStorage.getItem('email-management');
        if (serialisedState === null) {
            return undefined;
        }
        return JSON.parse(serialisedState);
    } catch (error) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem('email-management', serialisedState);
    } catch (error) {

    }
}

export const firstLetterUpperCase = function(string){

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

}
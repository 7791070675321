import {
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILURE
} from '../action/type';

const INTIAL_STATE = {};

export default function ( state , action ) {
    if (typeof state === 'undefined') {
        state = INTIAL_STATE
    }
    switch (action.type) {
        case GET_SERVICES_SUCCESS:
            state = action.payload;
            return state;

        case GET_SERVICES_FAILURE:
            state = {
                isServiceGetError : true,
                shiftGetErrorMessage : action.payload.message
            }
            return state;
        default :
            return state;
    }

}